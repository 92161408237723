
<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider" :style="{ overflow: 'auto'}">
      <div class="sider-title coustom-title auto">
        <span class="fl">条件查询</span>
        <!-- <a href="javascript:;" class="fr batch_search" @click="goBatchSearch">批量查询</a> -->
      </div>
      <search-bar :keys="filterkeys" @getSearch="getSearch" @getSearchCache="getSearchCache"></search-bar>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <div class="custom-content-warp auto" style="height:100%;box-sizing: border-box;overflow-y:auto;">
        <a-spin :spinning="loading">
          <div class="content-title auto">
            <div class="coustom-title fl">查询结果（{{count}}人）</div>
          </div>
          
          <div class="coustom-title-mes auto" v-show="!isCache">
            <a-icon type="info-circle" theme="filled" style="color:#226FBB;margin-right: 10px;" />为您检索到 {{count}} 个人员，其中：启用状态 {{mes['permit'] || 0}} 人，定期停用 {{mes['limittime'] || 0}} 人，停用状态 {{mes['prohibit'] || 0}} 人
          </div>

          <div class="content-bar auto">
            <custom-page :total="count" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
              <div slot="custom">
                <a-button type="primary" size="small" class="m-r-5" @click="addUser" :disabled="!userModify && !userAccountModify"> 添加人员 </a-button>
                <!-- <a-dropdown>val
                  <a-menu slot="overlay">
                    <a-menu-item key="1" @click="addUser">
                      单个添加
                    </a-menu-item>
                    <a-menu-item key="2" @click="goBatchUser">
                      批量导入
                    </a-menu-item>
                  </a-menu>
                  <a-button type="primary" size="small" class="m-r-5"> 添加人员 <a-icon type="down" /> </a-button>
                </a-dropdown> -->
                <!-- <a-button type="primary" size="small" class="m-r-5" @click="goBatchAccount"> 批量开通学工号 </a-button> -->
                <a-dropdown>
                  <a-menu slot="overlay">
                    <a-menu-item key="1" @click="goBatchUser" :disabled="!userModify">
                      批量导入人员
                    </a-menu-item>
                    <a-menu-item key="2" @click="goBatchUserModify" :disabled="!userModify">
                      批量修改人员
                    </a-menu-item>
                    <a-menu-item key="3" @click="goBatchAccount" :disabled="!userAccountModify">
                      批量导入账号
                    </a-menu-item>
                    <a-menu-item key="4" @click="goBatchAccountModify" :disabled="!userAccountModify">
                      批量修改账号
                    </a-menu-item>
                    <a-menu-item key="5" @click="goBatchUserAccount" :disabled="!userModify || !userAccountModify">
                      批量导入人员和账号
                    </a-menu-item>
                    <a-menu-item key="6" @click="goBatchUserAccountModify" :disabled="!userModify || !userAccountModify">
                      批量修改人员和账号
                    </a-menu-item>
                  </a-menu>
                  <a-button type="primary" size="small" class="m-r-5"> 批量操作 <a-icon type="down" /> </a-button>
                </a-dropdown>
                <a-dropdown>
                  <a-menu slot="overlay" @click="batchUserHandle">
                    <a-menu-item key="1" :disabled="!batchActions.canModify || !userModify">
                      启用
                    </a-menu-item>
                    <a-menu-item key="2" :disabled="!batchActions.canModify || !userModify">
                      停用
                    </a-menu-item>
                    <a-menu-item key="3" :disabled="!batchActions.canModify || !userModify">
                      定期停用
                    </a-menu-item>
                    <a-menu-item key="8" :disabled="!batchActions.canModify || !userModify">
                      开通账号
                    </a-menu-item>
                    <a-menu-item key="4" :disabled="!batchActions.canLockAccount || !userAccountModify">
                      锁定账号
                    </a-menu-item>
                    <a-menu-item key="5" :disabled="!batchActions.canUnLockAccount || !userAccountModify">
                      解锁账号
                    </a-menu-item>
                    <a-menu-item key="6" :disabled="!batchActions.canDeleteAccount || !userAccountModify">
                      删除账号
                    </a-menu-item>
                    <a-menu-item key="7" :disabled="!batchActions.canModify || !userModify">
                      删除人员
                    </a-menu-item>
                  </a-menu>
                  <a-button size="small" class="m-r-5"> 更多 <a-icon type="down" /> </a-button>
                </a-dropdown>
                <a-button type="primary" size="small" @click="userExportHandle" :loading="exportLoading">导出结果</a-button>
              </div>
            </custom-page>
          </div>
          <div class="content-table auto">
            <a-dropdown class="itemshowbox" v-model="itemshowboxVsible">
              <a @click="e => e.preventDefault()">
                <a-icon type="setting" />
              </a>
              <div class="columnfilterbox auto" slot="overlay">
                <div class="columnfilterboxtitle auto">
                  <p>列展示</p>
                  <a href="javascript:;" class="alink underline" @click="resetColumn">重置</a>
                </div>
                <div class="columnfilterboxcon auto">
                  <a-checkbox-group v-model="checkList" @change="onShowColumnChange">
                    <a-row v-for="(item, index) in tablecolumnsOri" :key="index">
                      <a-col :span="24">
                        <a-checkbox :value="item.dataIndex" :disabled="item.disabled">
                          {{ item.title }}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </div>
              </div>
            </a-dropdown>
            <!-- <a-table :rowKey="(record,index)=>{return record.id}" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false" :scroll="{ x: 1300 }"> -->
            <a-table :rowKey="(record,index)=>{return record.id}" :row-selection="rowSelection" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false" :scroll="{ x: 1550 }">
              <div slot="schoolid" slot-scope="text, record">
                <a href="javascript:;" @click="editHandle(record)" class="schoolidlink ellipsis" :title="text">{{text}}</a>
              </div>
              <div slot="name" slot-scope="text, record">
                <div class="fl auto ellipsis" style="width: 60px;" :title="text">
                  {{text}}
                </div>
                <div class="fl" style="width:35px;padding-top: 1px;">
                  <a-popover v-if="record.faceid" class="m-r-5 fl" overlayClassName="ant-popover-inner-content-custom" @visibleChange="visibleChange($event, record)" trigger="click" placement="rightTop">
                    <template slot="content" style="margin-top: -10px;">
                      <div style="width: 106px;height: 135px;text-align: center;">
                        <a-spin :spinning="userImageLoading" style="margin-top: 60px;" />
                        <img :src="listUserImage" alt="" style="width: 100%;">
                      </div>
                    </template>
                    <a-icon type="picture" theme="twoTone" two-tone-color="#226FBB" style="cursor: pointer;" />
                  </a-popover>
                  <a-tooltip v-if="record.modifyComment" class="fr">
                    <template slot="title">
                      {{record.modifyComment}}
                    </template>
                    <a-icon type="info-circle" theme="twoTone" two-tone-color="#FAAD14" />
                  </a-tooltip>
                </div>
              </div>
              <div slot="accountid" slot-scope="text">
                <span v-if="text">
                  <a-badge status="success" text="已注册" />
                </span>
                <span v-else>
                  <a-badge status="default" text="未注册" />
                </span>
              </div>
              <!-- <div slot="passwordStatus" slot-scope="text">
                <span v-if="text === 'normal'">
                  正常
                </span>
                <span v-else-if="text === 'forcereset'">
                  强制修改
                </span>
                <span v-else-if="text === 'remindmodify'">
                  提示修改
                </span>
                <span v-else></span>
              </div> -->
              <div slot="faceDisabled" slot-scope="text">
                <span v-if="text === '0'">
                  停用
                </span>
                <span v-else-if="text === '1'">
                  启用
                </span>
              </div>
              <div slot="department" slot-scope="text">
                <span v-for="(item, index) in text" :key="index">{{departmentName[item]}}<span v-show="index !== text.length - 1">，</span></span>
              </div>
              <div slot="type" slot-scope="text">
                {{usertypeName[text]}}
                <!-- <span v-for="(item, index) in text" :key="index">{{usertypeName[item]}}<span v-show="index !== text.length - 1">，</span></span> -->
              </div>
              <div slot="atSchoolStatus" slot-scope="text">
                <span>{{filters['atSchoolStatus'] && filters['atSchoolStatus'].find((item) => { return text === item.id}) && filters['atSchoolStatus'].find((item) => { return text === item.id})['name']}}</span>
              </div>
              <div slot="isDisabled" slot-scope="text, record">
                <span v-if="text === '0'">
                  <a-badge status="success" :text="filters['isDisabled'] && filters['isDisabled'].find((item) => { return text === item.id}) && filters['isDisabled'].find((item) => { return text === item.id})['name']" />
                </span>
                <span v-else>
                  <a-badge status="error" v-if="!record.deferDate" :text="filters['isDisabled'] && filters['isDisabled'].find((item) => { return text === item.id}) && filters['isDisabled'].find((item) => { return text === item.id})['name']" />
                  <a-badge status="warning" v-else :text="record.deferDate + '停用'" />
                </span>
              </div>
              <div slot="lockedStatus" slot-scope="text">
                {{text}}
              </div>
              <div slot="action" slot-scope="text, record">
                <!-- <a-button type="primary" size="small" class="m-r-5" @click="editHandle(record)">编辑</a-button> -->
                <a-popconfirm
                  title="确定要启用吗?"
                  ok-text="确定"
                  cancel-text="取消"
                  placement="topRight"
                  v-if="record.isDisabled == '1' && Boolean(record.canModify)"
                  @confirm="disabledOn(record)"
                >
                  <a-button type="primary" size="small" class="m-r-5 bggreen borgreen">启用</a-button>
                </a-popconfirm>
                <a-popconfirm
                  title="确定要停用吗?"
                  ok-text="确定"
                  cancel-text="取消"
                  placement="topRight"
                  v-if="(record.isDisabled == '0' || record.isDisabled == null) && Boolean(record.canModify) && userModify"
                  @confirm="disabledOff(record)"
                >
                  <a-button type="primary" size="small" class="m-r-5 bgred borred">停用</a-button>
                </a-popconfirm>
                <a-button type="primary" size="small" @click="deferDateHandle(record)" class="m-r-5 bgyellow boryellow" v-if="Boolean(record.canModify) && userModify">定期停用</a-button>
                <a-dropdown v-if="Boolean(!record.accountid && record.canAccountModify
) || Boolean(record.accountid && record.lockedStatus !== '管理员锁定' && record.lockedStatus !== '风险锁定' && record.canAccountModify
) || Boolean(record.accountid && record.lockedStatus !== '未锁定' && record.canAccountModify
) || Boolean(record.accountid && record.canAccountModify
) || Boolean(record.canModify)" class="m-r-5">
                  <a-menu slot="overlay" @click="moreHandle($event, record)">
                    <a-menu-item key="1" v-if="Boolean(!record.accountid && record.canAccountModify
) && userAccountModify">
                      开通账号
                    </a-menu-item>
                    <a-menu-item key="2" v-if="Boolean(record.accountid && record.lockedStatus !== '管理员锁定' && record.lockedStatus !== '风险锁定' && record.canAccountModify
) && userAccountModify">
                      锁定账号
                    </a-menu-item>
                    <a-menu-item key="3" v-if="Boolean(record.accountid && record.lockedStatus !== '未锁定' && record.canAccountModify
) && userAccountModify">
                      解锁账号
                    </a-menu-item>
                    <a-menu-item key="4" v-if="Boolean(record.accountid && record.canAccountModify
) && userAccountModify">
                      删除账号
                    </a-menu-item>
                    <a-menu-item key="5" v-if="Boolean(record.canModify) && userModify">
                      删除人员
                    </a-menu-item>
                  </a-menu>
                  <a-button size="small"> 更多 <a-icon type="down" /> </a-button>
                </a-dropdown>
              </div>
            </a-table>
          </div>
          <div class="content-bar auto">
            <custom-page :total="count" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
              <div slot="custom">
                <a-button type="primary" size="small" class="m-r-5" @click="addUser" :disabled="!userModify && !userAccountModify"> 添加人员 </a-button>
                <!-- <a-dropdown>
                  <a-menu slot="overlay">
                    <a-menu-item key="1" @click="addUser">
                      单个添加
                    </a-menu-item>
                    <a-menu-item key="2" @click="goBatchUser">
                      批量导入
                    </a-menu-item>
                  </a-menu>
                  <a-button type="primary" size="small" class="m-r-5"> 添加人员 <a-icon type="down" /> </a-button>
                </a-dropdown> -->
                <!-- <a-button type="primary" size="small" class="m-r-5" @click="goBatchAccount"> 批量开通学工号 </a-button> -->
                <a-dropdown>
                  <a-menu slot="overlay">
                    <a-menu-item key="1" @click="goBatchUser" :disabled="!userModify">
                      批量导入人员
                    </a-menu-item>
                    <a-menu-item key="2" @click="goBatchUserModify" :disabled="!userModify">
                      批量修改人员
                    </a-menu-item>
                    <a-menu-item key="3" @click="goBatchAccount" :disabled="!userAccountModify">
                      批量导入账号
                    </a-menu-item>
                    <a-menu-item key="4" @click="goBatchAccountModify" :disabled="!userAccountModify">
                      批量修改账号
                    </a-menu-item>
                    <a-menu-item key="5" @click="goBatchUserAccount" :disabled="!userModify || !userAccountModify">
                      批量导入人员和账号
                    </a-menu-item>
                    <a-menu-item key="6" @click="goBatchUserAccountModify" :disabled="!userModify || !userAccountModify">
                      批量修改人员和账号
                    </a-menu-item>
                  </a-menu>
                  <a-button type="primary" size="small" class="m-r-5"> 批量操作 <a-icon type="down" /> </a-button>
                </a-dropdown>
                <a-dropdown>
                  <a-menu slot="overlay" @click="batchUserHandle">
                    <a-menu-item key="1" :disabled="!batchActions.canModify || !userModify">
                      启用
                    </a-menu-item>
                    <a-menu-item key="2" :disabled="!batchActions.canModify || !userModify">
                      停用
                    </a-menu-item>
                    <a-menu-item key="3" :disabled="!batchActions.canModify || !userModify">
                      定期停用
                    </a-menu-item>
                    <a-menu-item key="8" :disabled="!batchActions.canModify || !userModify">
                      开通账号
                    </a-menu-item>
                    <a-menu-item key="4" :disabled="!batchActions.canLockAccount || !userAccountModify">
                      锁定账号
                    </a-menu-item>
                    <a-menu-item key="5" :disabled="!batchActions.canUnLockAccount || !userAccountModify">
                      解锁账号
                    </a-menu-item>
                    <a-menu-item key="6" :disabled="!batchActions.canDeleteAccount || !userAccountModify">
                      删除账号
                    </a-menu-item>
                    <a-menu-item key="7" :disabled="!batchActions.canModify || !userModify">
                      删除人员
                    </a-menu-item>
                  </a-menu>
                  <a-button size="small" class="m-r-5"> 更多 <a-icon type="down" /> </a-button>
                </a-dropdown>
                <a-button type="primary" size="small" @click="userExportHandle" :loading="exportLoading">导出结果</a-button>
              </div>
            </custom-page>
          </div>
        </a-spin>
      </div>
      <a-drawer
        width="100%"
        title=""
        placement="right"
        :closable="false"
        :visible="visible"
        :get-container="false"
        :wrap-style="{ position: 'absolute' }"
      >
        <a-spin :spinning="editLoading">
          <div class="drawer-bar auto">
            <a-button icon="double-left" size="small" class="m-r-10 fl" @click="onClose">返回</a-button>
            <a-button type="primary" size="small" class="fl" @click="submitSave" v-show="userModify || userAccountModify">保存</a-button>
            <div class="drawer-title auto fl">
              <span class="font16 grey333"><b>人员详情</b></span>
              <span v-if="current_row.canAccountQuery">
                <a href="javascript:;" class="alink greyccc" v-if="isAdd">查看操作日志</a>
                <a href="javascript:;" class="alink" @click="auditHandle" v-else>查看操作日志</a>
              </span>
            </div>
          </div>
          <div class="drawer-form auto" id="drawer-form">
            <a-form-model
              layout="inline"
              ref="ruleForm"
              :model="form"
              :rules="rules"
              class="ant-advanced-search-form"
            >
              <div class="row1">
                <a-row type="flex" style="width: 70%;min-width: 1000px;">
                  <a-col flex="312px">
                    <a-form-model-item ref="userImage" label="照片" prop="userImage">
                      <div class="poster-box auto">
                        <img :src="form.userImage" alt="" v-if="form.userImage && showDetailImg">
                        <div class="poster-wrap-cover auto">
                          <div class="poster-wrap-cover-box auto">
                            <a-button size="small" v-if="!showDetailImg && form.userImage" @click="showDetailImg = true">显示</a-button>
                            <a-upload
                              name="file"
                              :multiple="true"
                              :headers="headers"
                              :transform-file="transformFile"
                              accept="image/*"
                              v-if="userModify && !baseConfig.disableUserImage"
                              :showUploadList="false"
                            >
                              <a-button size="small" type="primary">上传</a-button>
                            </a-upload>
                          </div>
                        </div>
                      </div>
                    </a-form-model-item>
                  </a-col>
                  <a-col flex="auto">
                    <a-row type="flex">
                      <a-col flex="1">
                        <a-form-model-item ref="name" label="姓名" prop="name">
                          <a-input v-model="form.name" placeholder="请输入姓名" :disabled="!userModify || baseConfig.disableUserName" size="small" style="width:74%;" />
                        </a-form-model-item>
                      </a-col>
                      <a-col flex="1">
                        <a-form-model-item ref="sex" label="性别" prop="sex">
                          <a-select v-model="form.sex" placeholder="请选择性别" :disabled="!userModify || baseConfig.disableUserSex" size="small" style="width:74%;">
                            <a-select-option :value="item.id" v-for="(item, index) in filters['gender']" :key="index">{{item.name}}</a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <a-row type="flex">
                      <a-col flex="1">
                        <a-form-model-item ref="schoolid" label="学工号" prop="schoolid">
                          <a-input v-model="form.schoolid" placeholder="请输入学工号" size="small" :disabled="!isAdd" style="width:74%;"/>
                        </a-form-model-item>
                      </a-col>
                      <a-col flex="1">
                        <a-form-model-item ref="department" label="所属单位" prop="department">
                          <el-cascader v-model="form.department" :show-all-levels="false" :props="{ checkStrictly: true, label: 'name', value: 'id', children: 'children', multiple: true, emitPath: false }" filterable :options="filters['department']" :disabled="!userModify || baseConfig.disableUserDepartment" size="mini" style="width:74%;" :class="form.department && form.department.length > 0 ? 'custom-el-cascader-no-max' : 'custom-el-cascader'"></el-cascader>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <a-row type="flex">
                      <a-col flex="1">
                        <a-form-model-item ref="idCardType" label="证件类型" prop="idCardType">
                          <a-select v-model="form.idCardType" placeholder="请选择证件类型" :disabled="!userModify || baseConfig.disableUserIdCardType" size="small" style="width:74%;">
                            <a-select-option :value="item.id" v-for="(item, index) in filters['idCardType']" :key="index">{{item.name}}</a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                      <a-col flex="1">
                        <a-form-model-item ref="idCardNumber" label="证件号码" prop="idCardNumber">
                          <a-input v-model="form.idCardNumber" placeholder="请输入证件号码" :disabled="!userModify || baseConfig.disableUserIdCardNumber" size="small" style="width:74%;"/>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <a-row type="flex">
                      <a-col flex="1">
                        <a-form-model-item ref="phone" label="手机" prop="phone">
                          <a-input v-model="form.phone" placeholder="请输入手机号" size="small" style="width:74%;" :disabled="!current_row.canAccountModify || baseConfig.disableUserPhone"/>
                        </a-form-model-item>
                      </a-col>
                      <a-col flex="1">
                        <a-form-model-item ref="email" label="邮箱" prop="email">
                          <a-input v-model="form.email" placeholder="请输入邮箱" size="small" style="width:calc((100%) * 0.74);" :disabled="!current_row.canAccountModify || baseConfig.disableUserEmail"/>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <a-row type="flex">
                      <a-col flex="1">
                        <a-form-model-item ref="political" label="政治面貌" prop="political">
                          <a-select v-model="form.political" placeholder="请选择政治面貌" :disabled="!userModify || baseConfig.disableUserPolitical" size="small" style="width: 74%;">
                            <a-select-option :value="item.id" v-for="(item, index) in filters['political']" :key="index">{{item.name}}</a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                      <a-col flex="1">
                        <a-form-model-item ref="atSchoolStatus" label="在校状态" prop="atSchoolStatus">
                          <a-select v-model="form.atSchoolStatus" placeholder="在校状态" :disabled="!userModify || baseConfig.disableUserAtSchoolStatus" size="small" style="width:74%;">
                            <a-select-option :value="item.id" v-for="(item, index) in filters['atSchoolStatus']" :key="index">{{item.name}}</a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <a-row type="flex">
                      <a-col flex="1">
                        <a-form-model-item ref="startDate" label="入校日期" prop="startDate">
                          <a-date-picker v-model="form.startDate" :disabled="!userModify || baseConfig.disableUserStartDate" size="small" valueFormat="YYYY-MM-DD" style="width:74%;" />
                        </a-form-model-item>
                      </a-col>
                      <a-col flex="1">
                        <a-form-model-item ref="endDate" label="预计离校日期" prop="endDate">
                          <a-date-picker v-model="form.endDate" :disabled="!userModify || baseConfig.disableUserEndDate" size="small" valueFormat="YYYY-MM-DD" style="width:74%;" />
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
                <a-row type="flex" style="width: 70%;min-width: 1000px;">
                  <a-col flex="312px">
                    <a-form-model-item label="注册状态">
                      {{current_row.accountid ? '已注册' : '未注册'}}
                    </a-form-model-item>
                  </a-col>
                  <a-col flex="auto">
                    <a-row type="flex">
                      <a-col flex="1">
                        <a-form-model-item ref="type" label="人员类别" prop="type" size="small">
                          <el-cascader v-model="form.type" :show-all-levels="false" :props="{ checkStrictly: true, label: 'name', value: 'id', children: 'children', emitPath: false }" filterable :options="filters['usertype']" :disabled="!userModify || baseConfig.disableUserType" size="small" style="width:74%;" class="custom-el-cascader"></el-cascader>
                        </a-form-model-item>
                      </a-col>
                      <a-col flex="1">
                        <a-form-model-item ref="isDisabled" label="启用状态" prop="isDisabled">
                          <a-radio-group :disabled="!userModify || baseConfig.disableUserIsDisabled" size="small" v-model="form.isDisabled" :options="filters['isDisabled2']" />
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
                <a-row type="flex" style="width: 70%;min-width: 1000px;">
                  <a-col flex="312px">
                    <a-form-model-item label="静态标签" prop="staticTags">
                      <a-select v-model="form.staticTags" placeholder="请选择静态标签" :disabled="!userModify || baseConfig.disableUserStaticTags" size="small" style="width:74%;" mode="multiple" :max-tag-count="2" allowClear>
                        <a-select-option :value="item.id" v-for="(item, index) in staticOptions" :key="index">{{item.name}}</a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                  <a-col flex="auto">
                    <a-row type="flex">
                      <a-col flex="1">
                        <a-form-model-item ref="deferDate" label="定期停用" prop="deferDate">
                          <a-date-picker valueFormat="YYYY-MM-DD" v-model="form.deferDate" :disabled="!userModify || baseConfig.disableUserDeferDate" size="small" style="width:74%;" />
                        </a-form-model-item>
                      </a-col>
                      <a-col flex="1">
                        <a-form-model-item ref="faceDisabled" label="人脸识别" prop="faceDisabled">
                          <a-radio-group :disabled="!userModify || baseConfig.disableUserIsDisabled" size="small" v-model="form.faceDisabled" :options="filters['isDisabled3']" />
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
                <a-row type="flex" style="width: 70%;min-width: 1000px;">
                  <a-col :span="24">
                    <a-form-model-item ref="modifyComment" label="备注" prop="modifyComment" style="width:100%;" >
                      <a-input type="tex" v-model="form.modifyComment" placeholder="请输入备注" :disabled="!userModify || baseConfig.disableUserModifyComment" style="width:calc((100% - 212px) / 2 + 212px + 100px + (((100% - 212px) / 2 - 100px) * 0.74));" size="small"/>
                      <!-- style="width:calc((100% - 100px) * 0.9 + (100% - 100px) / 2 * 0.1 + 100px);" -->
                    </a-form-model-item>
                  </a-col>
                </a-row>
                
                <div v-if="filters['attribute'] && filters['attribute'].length">
                  <div v-for="i in Math.ceil(filters['attribute'].length/3)" :key="i">
                    <a-row type="flex" style="width: 70%;min-width: 1000px;">
                      <a-col flex="312px" v-if="filters['attribute'][i * 3 - 3]">
                        <a-form-model-item :label="filters['attribute'][i * 3 - 3]['name_zh']">
                          <a-date-picker v-if="filters['attribute'][i * 3 - 3]['type'] === 'TIMESTAMP'" valueFormat="YYYY-MM-DD" v-model="form.attribute[filters['attribute'][i * 3 - 3]['name_en']]" :disabled="filters['attribute'][i * 3 - 3]['is_editabled'] ? false : true"  size="small" style="width:74%;" />
                          <a-input v-if="filters['attribute'][i * 3 - 3]['type'] === 'VARCHAR'" v-model="form.attribute[filters['attribute'][i * 3 - 3]['name_en']]" placeholder="自定义内容" size="small" :disabled="filters['attribute'][i * 3 - 3]['is_editabled'] ? false : true" style="width:74%;"/>
                          <a-input v-if="filters['attribute'][i * 3 - 3]['type'] === 'INTEGER' || filters['attribute'][i * 3 - 3]['type'] === 'DOUBLE'" type="number" v-model="form.attribute[filters['attribute'][i * 3 - 3]['name_en']]" placeholder="自定义内容" size="small" :disabled="filters['attribute'][i * 3 - 3]['is_editabled'] ? false : true" style="width:74%;"/>
                          <a href="javascript:;" v-if="filters['attribute'][i * 3 - 3]['type'] === 'TEXT'" class="alink underline" @click="attrTextOpen(filters['attribute'][i * 3 - 3]['name_en'], filters['attribute'][i * 3 - 3]['is_editabled'] ? false : true)">自定义长文本…</a>
                        </a-form-model-item>
                      </a-col>
                      <a-col flex="auto">
                        <a-row type="flex">
                          <a-col flex="1">
                            <a-form-model-item :label="filters['attribute'][i * 3 - 2]['name_zh']" v-if="filters['attribute'][i * 3 - 2]">
                              <a-date-picker v-if="filters['attribute'][i * 3 - 2]['type'] === 'TIMESTAMP'" valueFormat="YYYY-MM-DD" v-model="form.attribute[filters['attribute'][i * 3 - 2]['name_en']]" :disabled="filters['attribute'][i * 3 - 2]['is_editabled'] ? false : true"  size="small" style="width:74%;" />
                              <a-input v-if="filters['attribute'][i * 3 - 2]['type'] === 'VARCHAR'" v-model="form.attribute[filters['attribute'][i * 3 - 2]['name_en']]" placeholder="自定义内容" size="small" :disabled="filters['attribute'][i * 3 - 2]['is_editabled'] ? false : true" style="width:74%;"/>
                              <a-input v-if="filters['attribute'][i * 3 - 2]['type'] === 'INTEGER' || filters['attribute'][i * 3 - 2]['type'] === 'DOUBLE'" type="number" v-model="form.attribute[filters['attribute'][i * 3 - 3]['name_en']]" placeholder="自定义内容" size="small" :disabled="filters['attribute'][i * 3 - 2]['is_editabled'] ? false : true" style="width:74%;"/>
                              <a href="javascript:;" v-if="filters['attribute'][i * 3 - 2]['type'] === 'TEXT'" class="alink underline" @click="attrTextOpen(filters['attribute'][i * 3 - 2]['name_en'], filters['attribute'][i * 3 - 2]['is_editabled'] ? false : true)">自定义长文本…</a>
                            </a-form-model-item>
                          </a-col>
                          <a-col flex="1">
                            <a-form-model-item :label="filters['attribute'][i * 3 - 1]['name_zh']" v-if="filters['attribute'][i * 3 - 1]">
                              <a-date-picker v-if="filters['attribute'][i * 3 - 1]['type'] === 'TIMESTAMP'" valueFormat="YYYY-MM-DD" v-model="form.attribute[filters['attribute'][i * 3 - 1]['name_en']]"  :disabled="filters['attribute'][i * 3 - 1]['is_editabled'] ? false : true" size="small" style="width:74%;" />
                              <a-input v-if="filters['attribute'][i * 3 - 1]['type'] === 'VARCHAR'" v-model="form.attribute[filters['attribute'][i * 3 - 1]['name_en']]" placeholder="自定义内容" size="small" :disabled="filters['attribute'][i * 3 - 1]['is_editabled'] ? false : true" style="width:74%;"/>
                              <a-input v-if="filters['attribute'][i * 3 - 1]['type'] === 'INTEGER' || filters['attribute'][i * 3 - 1]['type'] === 'DOUBLE'" type="number" v-model="form.attribute[filters['attribute'][i * 3 - 3]['name_en']]" placeholder="自定义内容" size="small" :disabled="filters['attribute'][i * 3 - 1]['is_editabled'] ? false : true" style="width:74%;"/>
                              <a href="javascript:;" v-if="filters['attribute'][i * 3 - 1]['type'] === 'TEXT'" class="alink underline" @click="attrTextOpen(filters['attribute'][i * 3 - 1]['name_en'], filters['attribute'][i * 3 - 1]['is_editabled'] ? false : true)">自定义长文本…</a>
                            </a-form-model-item>
                          </a-col>
                        </a-row>
                      </a-col>
                    </a-row>
                  </div>
                </div>
                
                <!-- <a-row type="flex" style="width: 70%;min-width: 1000px;">
                  <a-col flex="340px">
                    <a-form-model-item label="动态标签" prop="dynamicTags">
                      <div v-if="form.dynamicTags">
                        <a-tooltip v-for="(item, index) in form.dynamicTags" :key="index">
                          <template #title>{{dynamicOptions.find(item1 => item1.id == item) && dynamicOptions.find(item1 => item1.id == item)['name']}}</template>
                          <a-tag>{{dynamicOptions.find(item1 => item1.id == item) && dynamicOptions.find(item1 => item1.id == item)['name'].slice(0, 10)}}</a-tag>
                        </a-tooltip>
                      </div>
                    </a-form-model-item>
                  </a-col>
                  <a-col flex="auto">
                    
                  </a-col>
                </a-row> -->
              </div>

              <div class="row1" style="position: relative;" v-if="current_row.canAccountQuery">
                <a-row type="flex" style="width: 70%;min-width: 1000px;">
                  <a-col flex="312px">
                    <a-form-model-item ref="username" label="账号" prop="username">
                      <a-input v-model="form.username" placeholder="留空时账号为学工号" size="small" style="width:74%;" :disabled="!current_row.canAccountModify || baseConfig.disableUserUsername"/>
                    </a-form-model-item>
                  </a-col>
                  <a-col flex="auto">
                    <a-form-model-item ref="password" prop="password" :label-col="{span: 4}" :wrapper-col="{span: 20}">
                      <template slot="label">
                        <a-tooltip style="margin-top:7px;" :overlayStyle="{maxWidth: '500px'}">
                          <template slot="title">
                            留空则保持密码不变<br />
                            管理员不受密码强度限制，但若非必要请勿设置弱密码
                          </template>
                          <a-icon type="info-circle" theme="twoTone" two-tone-color="#bbbbbb" />
                        </a-tooltip>
                        密码
                      </template>
                      <a-input v-model="form.password" placeholder="请输入密码" size="small" class="fl" style="margin-top: 3px;width:calc((100% - 100px) / 2 * 0.74);" :disabled="!current_row.canAccountModify || baseConfig.disableUserPassword"/>
                      <p class="paslabel fl">
                        <span :class="modes > 0 ? 'bgred' : ''">弱</span>
                        <span :class="modes > 1 ? 'bgyellow' : ''">中</span>
                        <span :class="modes > 2 ? 'bggreen' : ''">强</span>
                      </p>
                      <!-- <a-tooltip class="fl" style="margin: 8px 0 0 5px">
                        <template slot="title">
                          留空则保持密码不变<br />
                          管理员不受密码强度限制，但若非必要请勿设置弱密码
                        </template>
                        <a-icon type="info-circle" theme="twoTone" two-tone-color="#bbbbbb" />
                      </a-tooltip> -->
                      <div class="fl" style="font-size: 12px;">
                        <div style="color: red;" v-if="current_row.passwordStatus === 'forcereset'">
                          <a-icon type="info-circle" />
                          密码将在下次登录时强制修改
                        </div>
                        <div style="color: #fdb600;" v-if="current_row.passwordStatus === 'remindmodify'">
                          <a-icon type="info-circle" />
                          密码将在下次登录时提醒修改
                        </div>
                      </div>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <!-- <a-row type="flex" style="width: 70%;min-width: 1000px;">
                  <a-col flex="312px">
                    <a-form-model-item ref="phone" label="手机" prop="phone">
                      <a-input v-model="form.phone" placeholder="请输入手机号" size="small" style="width:74%;" :disabled="!current_row.canAccountModify || baseConfig.disableUserPhone"/>
                    </a-form-model-item>
                  </a-col>
                  <a-col flex="auto">
                    <a-row type="flex">
                      <a-col flex="1">
                        <a-form-model-item ref="email" label="邮箱" prop="email">
                          <a-input v-model="form.email" placeholder="请输入邮箱" size="small" style="width:calc((100%) * 0.74);" :disabled="!current_row.canAccountModify || baseConfig.disableUserEmail"/>
                        </a-form-model-item>
                      </a-col>
                      <a-col flex="1">
                        <a-form-model-item label="TOTP状态">
                          <span v-if="!form.accounttotpid">未绑定</span>
                          <span v-else>已绑定</span>
                          <a href="javascript:;" class="underline m-l-10" @click="unbindTotp" v-if="form.accounttotpid && !baseConfig.disableUserUnbind">解绑</a>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row> -->
                <a-row type="flex" style="width: 70%;min-width: 1000px;">
                  <a-col flex="312px">
                    <a-form-model-item label="TOTP状态">
                      <span v-if="!form.accounttotpid">未绑定</span>
                      <span v-else>已绑定</span>
                      <a href="javascript:;" class="underline m-l-10" @click="unbindTotp" v-if="form.accounttotpid && !baseConfig.disableUserUnbind">解绑</a>
                    </a-form-model-item>
                  </a-col>
                  <a-col flex="auto">
                    <a-row type="flex">
                      <a-col flex="1">
                        <a-form-model-item label="闲置状态">
                          {{form.unuseStatus}}
                        </a-form-model-item>
                      </a-col>
                      <a-col flex="1">
                        <a-form-model-item label="锁定状态">
                          {{current_row.lockedStatus}}
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
                <a-row type="flex" style="width: 70%;min-width: 1000px;">
                  <a-col flex="312px">
                    <a-form-model-item label="最后登录时间">
                      <!-- 2020/05/05 20:20:20 -->
                      {{current_row.lastLoginDate}}
                    </a-form-model-item>
                  </a-col>
                  <a-col flex="auto">
                    <a-row type="flex">
                      <a-col flex="1">
                        <a-form-model-item label="登录日志">
                          <a href="javascript:;" class="grey666" v-if="isAdd || baseConfig.disableUserCheckLog">查询</a>
                          <a href="javascript:;" class="underline" @click="checkLog" v-else>查询</a>
                        </a-form-model-item>
                      </a-col>
                      <!-- <a-col flex="1">
                        <a-form-model-item label="双因素认证">
                          <a href="javascript:;" class="grey666" v-if="isAdd || baseConfig.disableUserCheckAuth">设置</a>
                          <a href="javascript:;" class="underline" @click="checkAuth" v-else>设置</a>
                        </a-form-model-item>
                      </a-col> -->
                      <a-col flex="1">
                        <a-form-model-item label="消息提醒">
                          <a href="javascript:;" class="grey666" v-if="isAdd || baseConfig.disableUserCheckTips">设置</a>
                          <a href="javascript:;" class="underline" @click="checkTips" v-else>设置</a>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
                <div class="newAccount auto" v-show="showAddAccountBtn">
                  <div class="newAccount-box">
                    <a-button class="new-account-btn" @click="showAddAccountHandle" :disabled="!current_row.canAccountModify || baseConfig.disableUserAddAccount">立即开通账号</a-button>
                  </div>
                </div>
              </div>
            </a-form-model>
          </div>

          <div class="drawer-bar drawer-bar-bot auto">
            <a-button icon="double-left" size="small" class="m-r-10" @click="onClose">返回</a-button>
            <a-button type="primary" size="small" @click="submitSave" v-show="userModify || userAccountModify">保存</a-button>
          </div>
        </a-spin>
      </a-drawer>
    </a-layout-content>

    <a-modal v-model="auditVisible" :title="'操作日志查询（' + current_row.schoolid + ' ，' + current_row.name + '）'" :footer="null" @cancel="auditClose" width="900px">
      <a-spin :spinning="auditlogLoading">
        <div id="log-search-bar">
          <a-form-model
              layout="inline"
              ref="auditForm"
              :model="auditform"
              class="ant-advanced-search-form form-30-height"
            >
            <a-row>
              <a-col :span="8">
                <a-form-model-item ref="ip" label="源IP" prop="ip">
                  <a-input v-model="auditform.ip" placeholder="请输入源IP" size="small"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="10">
                <a-form-model-item ref="date" label="时间" prop="date">
                  <a-range-picker valueFormat="YYYY-MM-DD" v-model="auditform.date" size="small">
                    <a-icon slot="calendar" type="smile" />
                  </a-range-picker>
                </a-form-model-item>
              </a-col>
              <a-col :span="6"></a-col>
            </a-row>
            <a-row>
              <a-col :span="8">
                <a-form-model-item ref="comments" label="详情" prop="comments">
                  <a-input v-model="auditform.comments" placeholder="请输入关键词" size="small"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="10">
                <a-form-model-item ref="type" label="日志类型" prop="type">
                  <a-select v-model="auditform.type" placeholder="请选择日志类型" size="small">
                    <a-select-option :value="item.id" v-for="(item, index) in filters['auditlog']" :key="index">{{item.name}}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <div class="fr log-btns">
                  <a-button type="link" size="small" @click="auditlogReset">重置</a-button>
                  <a-button type="primary" size="small" @click="getAuditLogListHandle">查询</a-button>
                </div>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
        <div class="log-table">
          <a-table :rowKey="(record,index)=>{return index}" :pagination="false" :columns="auditTableColumns" :data-source="auditTableData">
            <span slot="action1" slot-scope="text,record,index">{{ index + (auditPage.page_size * (auditPage.current_page - 1)) + 1 }}</span>
            <span slot="type" slot-scope="text">
              <!-- {{ filters['auditlog'].find(item => {return item.id === text}) ? filters['auditlog'].find(item => {return item.id === text})['name'] : '' }} -->
              {{text.split('-')[0]}}
            </span>
            <div slot="action" slot-scope="text, record">
              <a-button size="small" @click="showDetial(record)" class="m-r-5">详情</a-button>
            </div>
            <div slot="commentsKey" slot-scope="text, record">
              <a-tooltip placement="left">
                <template slot="title">
                  <div v-if="record.commentsBefore">
                    操作前：
                    <span v-for="(item, index) in record.commentsBefore" :key="index">
                      {{record.commentsKey[index]}} > {{item}}，
                    </span>
                  </div>
                  <div v-if="record.commentsAfter">
                    操作后：
                    <span v-for="(item, index) in record.commentsAfter" :key="index">
                      {{record.commentsKey[index]}} > {{item}}，
                    </span>
                  </div>
                </template>
                <div v-if="record.commentsBefore">
                  操作前：
                  <span v-for="(item, index) in record.commentsBefore" :key="index">
                    {{record.commentsKey[index]}} > {{item}}，
                  </span>
                </div>
                <div v-if="record.commentsAfter">
                  操作后：
                  <span v-for="(item, index) in record.commentsAfter" :key="index">
                    {{record.commentsKey[index]}} > {{item}}，
                  </span>
                </div>
              </a-tooltip>
            </div>
          </a-table>
        </div>
        <div class="log-page">
          <custom-page :total="auditCount" :page="auditPage.current_page" :limit="auditPage.page_size" @getPageMes="getAuditPageMes"></custom-page>
        </div>
      </a-spin>
    </a-modal>
    <a-modal v-model="detailVisible" title="详情" :footer="null" @cancel="detailClose" width="1000px">
      <div class="detail-log-table">
        <div class="tablehead auto">
          <div class="tableheadcolumn tableheadcolumn1 auto">名称</div>
          <div class="tableheadcolumn tableheadcolumn2 auto">操作前</div>
          <div class="tableheadcolumn tableheadcolumn3 auto">操作后</div>
        </div>
        <div class="tablebody auto">
          <!-- v-show="detail_current_row.commentsBefore[index] !== detail_current_row.commentsAfter[index]" -->
          <div v-if="detail_current_row.commentsAfter">
            <div class="tablebodyh auto" v-for="(item, index) in detail_current_row.commentsAfter" :key="index" v-show="(detail_current_row.commentsBefore && detail_current_row.commentsBefore[index] ? detail_current_row.commentsBefore[index] : '') !== (detail_current_row.commentsAfter && detail_current_row.commentsAfter[index] ? detail_current_row.commentsAfter[index] : '')">
              <div class="tablebodycolumn tablebodycolumn1 auto ellipsis">
                {{detail_current_row.commentsKey[index]}}
              </div>
              <div class="tablebodycolumn tablebodycolumn2 auto ellipsis">
                <a-tooltip placement="left" :title="detail_current_row.commentsBefore && detail_current_row.commentsBefore[index]" :overlayStyle="{maxWidth: '400px'}">
                  {{detail_current_row.commentsBefore && detail_current_row.commentsBefore[index] ? detail_current_row.commentsBefore[index] : '——'}}
                </a-tooltip>
              </div>
              <div class="tablebodycolumn tablebodycolumn3 auto ellipsis">
                <a-tooltip placement="left" :title="detail_current_row.commentsAfter && detail_current_row.commentsAfter[index]" :overlayStyle="{maxWidth: '400px'}">
                  {{detail_current_row.commentsAfter && detail_current_row.commentsAfter[index] ? detail_current_row.commentsAfter[index] : '——'}}
                </a-tooltip>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="tablebodyh auto" v-for="(item, index) in detail_current_row.commentsBefore" :key="index" v-show="(detail_current_row.commentsBefore && detail_current_row.commentsBefore[index] ? detail_current_row.commentsBefore[index] : '') !== (detail_current_row.commentsAfter && detail_current_row.commentsAfter[index] ? detail_current_row.commentsAfter[index] : '')">
              <div class="tablebodycolumn tablebodycolumn1 auto ellipsis">
                {{detail_current_row.commentsKey[index]}}
              </div>
              <div class="tablebodycolumn tablebodycolumn2 auto ellipsis">
                <a-tooltip placement="left" :title="detail_current_row.commentsBefore && detail_current_row.commentsBefore[index]" :overlayStyle="{maxWidth: '400px'}">
                  {{detail_current_row.commentsBefore && detail_current_row.commentsBefore[index] ? detail_current_row.commentsBefore[index] : '——'}}
                </a-tooltip>
              </div>
              <div class="tablebodycolumn tablebodycolumn3 auto ellipsis">
                <a-tooltip placement="left" :title="detail_current_row.commentsAfter && detail_current_row.commentsAfter[index]" :overlayStyle="{maxWidth: '400px'}">
                  {{detail_current_row.commentsAfter && detail_current_row.commentsAfter[index] ? detail_current_row.commentsAfter[index] : '——'}}
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal v-model="logVisible" :title="'登录日志查询（' + current_row.schoolid + ' ，' + current_row.name + '）'" :footer="null" @cancel="logClose" width="900px">
      <a-spin :spinning="loginlogLoading">
        <div id="log-search-bar">
          <a-form-model
              layout="inline"
              ref="logForm"
              :model="logform"
              class="ant-advanced-search-form form-30-height"
            >
            <a-row>
              <a-col :span="8">
                <a-form-model-item ref="ip" label="登录IP" prop="ip">
                  <a-input v-model="logform.ip" placeholder="请输入登录IP" size="small"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="15">
                <a-form-model-item ref="date" label="时间" prop="date">
                  <!-- <a-range-picker valueFormat="YYYY-MM-DD" v-model="logform.date" size="small">
                    <a-icon slot="calendar" type="smile" />
                  </a-range-picker> -->
                  <a-range-picker v-model="logform.date" :show-time="{ format: 'HH:mm:ss' }" valueFormat="YYYY-MM-DD HH:mm:ss" @change="changeTime" :disabled-date="disabledDate" @calendarChange="calendarChange" size="small" :allowClear="false" style="width:330px;">
                    <a-icon slot="calendar" type="smile" />
                    <template slot="renderExtraFooter">
                      查询区间最长半年
                    </template>
                  </a-range-picker>
                </a-form-model-item>
              </a-col>
              <a-col :span="1"></a-col>
            </a-row>
            <a-row>
              <a-col :span="8">
                <a-form-model-item ref="comments" label="详情" prop="comments">
                  <a-input v-model="logform.comments" placeholder="请输入关键词" size="small"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item ref="type" label="日志类型" prop="type">
                  <a-select v-model="logform.type" placeholder="请选择日志类型" size="small" style="width:330px;">
                    <a-select-option :value="item.id" v-for="(item, index) in filters['loginlog']" :key="index">{{item.name}}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="4">
                <div class="fr log-btns">
                  <a-button type="link" size="small" @click="logReset">重置</a-button>
                  <a-button type="primary" size="small" @click="getLoginLogListHandle">查询</a-button>
                </div>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
        <div class="log-table">
          <a-table :rowKey="(record,index)=>{return index}" :pagination="false" :columns="logTableColumns" :data-source="logTableData">
            <span slot="action" slot-scope="text,record,index">{{ index + (logPage.page_size * (logPage.current_page - 1)) + 1 }}</span>
            <span slot="type" slot-scope="text">
              {{ filters['loginlog'].find(item => {return item.id === text}) ? filters['loginlog'].find(item => {return item.id === text})['name'] : '' }}
            </span>
          </a-table>
        </div>
        <div class="log-page">
          <custom-page :total="logCount" :page="logPage.current_page" :limit="logPage.page_size" @getPageMes="getLoginPageMes"></custom-page>
        </div>
      </a-spin>
    </a-modal>
    <a-modal v-model="authVisible" :title="'双因素认证设置（' + current_row.schoolid + ' ，' + current_row.name + '）'" @ok="authClose" width="900px">
      <template slot="footer">
        <a-button key="back" @click="authVisible = false">
          取 消
        </a-button>
        <a-button key="submit" type="primary" :loading="multifactorLoading" @click="authClose" v-if="current_row.canAccountModify">
          确 定
        </a-button>
      </template>
      <ul class="auth-box-ul">
        <li v-for="(item, index) in multifactorList" :key="index">
          <a-checkbox v-model="multifactorList[index]['selected']" :disabled="item.required === 'N' || item.required === 'F' || !current_row.canAccountModify">
            {{item.name}}
          </a-checkbox>
        </li>
      </ul> 
    </a-modal>
    <a-modal v-model="tipsVisible" :title="'消息提醒设置（' + current_row.schoolid + '  ，' + current_row.name + '）'" @ok="tipsClose" width="900px">
      <template slot="footer">
        <a-button key="back" @click="tipsVisible = false">
          取 消
        </a-button>
        <a-button key="submit" type="primary" :loading="notificationLoading" @click="tipsClose" v-if="current_row.canAccountModify">
          确 定
        </a-button>
      </template>
      <div class="tips-content auto">
        <div class="tips-row auto">
          <a-row>
            <a-col :span="5" align="center">
              <span class="font16 gray999">正常登录</span>
            </a-col>
            <a-col :span="19">
              <dl><dt>认证成功：</dt><dd><a-switch size="small" :disabled="!current_row.canAccountModify" v-model="tipsForm.loginNormal.authenticationSuccess" /><span class="grey999">是否提醒</span></dd></dl>
              <dl><dt>登入成功：</dt><dd><a-button size="small" @click="setloginTips">设置</a-button></dd></dl>
            </a-col>
          </a-row>
        </div>
        <div class="tips-row auto">
          <a-row>
            <a-col :span="5" align="center">
              <span class="font16 gray999">账号变更</span>
            </a-col>
            <a-col :span="19">
              <dl><dt>修改密码：</dt><dd><a-switch size="small" :disabled="!current_row.canAccountModify" v-model="tipsForm.accountChange.modifyPassword" /><span class="grey999">是否提醒</span></dd></dl>
              <dl><dt>修改手机：</dt><dd><a-switch size="small" :disabled="!current_row.canAccountModify" v-model="tipsForm.accountChange.modifyPhone" /><span class="grey999">是否提醒</span></dd></dl>
              <dl><dt>修改邮箱：</dt><dd><a-switch size="small" :disabled="!current_row.canAccountModify" v-model="tipsForm.accountChange.modifyEmail" /><span class="grey999">是否提醒</span></dd></dl>
            </a-col>
          </a-row>
        </div>
        <div class="tips-row nobor auto">
          <a-row>
            <a-col :span="5" align="center">
              <span class="font16 gray999">账号异常</span>
            </a-col>
            <a-col :span="19">
              <dl><dt>暴力破解：</dt><dd><a-switch size="small" :disabled="!current_row.canAccountModify" v-model="tipsForm.accountAbnormal.bruteforceAttack" /><span class="grey999">是否提醒</span></dd></dl>
              <dl><dt>弱密码：</dt><dd><a-switch size="small" :disabled="!current_row.canAccountModify" v-model="tipsForm.accountAbnormal.weakPassword" /><span class="grey999">是否提醒</span></dd></dl>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-modal>
    <a-modal v-model="loginTipsVisible" @ok="loginTipsClose" width="600px">
      <template slot="title">
        登入提醒设置
      </template>
      <template slot="footer">
        <a-button key="back" @click="loginTipsVisible = false">
          取 消
        </a-button>
        <a-button key="submit" type="primary" @click="loginTipsVisible = false" v-if="current_row.canAccountModify">
          确 定
        </a-button>
      </template>
      <div class="loginTipsBtnBox">
        <!-- <a-button size="small" type="primary" @click="addSysHandle" v-if="current_row.canAccountModify">添加单独设置</a-button> -->
        <a-row>
          <a-col :span="3">添加系统:</a-col>
          <a-col :span="21">
            <a-select v-model="singleTip" show-search placeholder="请选择系统" option-filter-prop="children" style="width:150px;margin-right:5px;" size="small">
              <a-select-option :value="item.id" v-for="(item, index) in filters['service']" :key="index">
                {{item.name}}
              </a-select-option>
            </a-select>
            <a-button size="small" type="primary" @click="addSysClose">添加</a-button>
          </a-col>
        </a-row>
      </div>
      <a-table :rowKey="(record,index)=>{return record.serviceid}" :pagination="false" :columns="loginTipsTableHeader" :data-source="tipsForm.loginNormal.loginSuccess" :scroll="{ y: 300 }">
        <span slot="servicename" slot-scope="text">{{text}}</span>
        <span slot="selected" slot-scope="text, record"><a-switch :disabled="!current_row.canAccountModify" v-model="record.selected" size="small" /></span>
        <span slot="action" slot-scope="record"><a-button size="small" :disabled="!current_row.canAccountModify" type="danger" @click="deleteSuccessTips(record)">删除</a-button></span>
      </a-table>
    </a-modal>
    <a-modal v-model="addSysVisible" title="添加单独设置" @ok="addSysClose" width="430px">
      <a-row>
        <a-col :span="5">选择系统：</a-col>
        <a-col :span="19">
          <a-select v-model="singleTip" show-search placeholder="请选择系统" option-filter-prop="children" style="width:100%;" size="small">
            <a-select-option :value="item.id" v-for="(item, index) in filters['service']" :key="index">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
    </a-modal>
    <a-modal v-model="deferDateVisible" title="定期停用" @cancel="deferDateClose" @ok="deferDateSubmit" width="430px">
      <a-form-model
        ref="deferRefForm"
        :model="deferForm"
        :rules="deferRules"
        :label-col="{span: 5}"
        :wrapper-col="{span: 19}"
        class="ant-advanced-search-form"
      >
        <a-form-model-item label="停用日期" prop="deferDate">
          <a-date-picker v-model="deferForm.deferDate" size="small" valueFormat="YYYY-MM-DD" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="deferComment">
          <a-input type="textarea" v-model="deferForm.deferComment"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal v-model="attrTextVisible" title="自定义" width="900px">
      <a-textarea v-model="attributeText" :disabled="attributeTextCanEdit" :auto-size="{ minRows: 8, maxRows: 10 }"></a-textarea>
      <template slot="footer">
        <a-button key="back" @click="attrTextClose">
          取 消
        </a-button>
        <a-button key="submit" type="primary" v-if="!attributeTextCanEdit" @click="attrTextSave">
          保 存
        </a-button>
      </template>
    </a-modal>
  </a-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { Auth, User, Filter } from '@/model'
export default {
  data () {
    return {
      baseConfig: window.config,
      status: false,
      hasQuery: false,
      batchActions: {
        canModify: true,
        canDeleteAccount: true,
        canLockAccount: true,
        canUnLockAccount: true
      },
      chooseAll: false,
      batchid: '',
      overflowText: 'auto',
      headers: {
        authorization: 'authorization-text',
      },
      isAdd: true,
      departmentName: {},
      departmentTreeData: {},
      usertypeName: {},
      usertypeTreeData: {},
      filters: {},
      loading: false,
      filterkeys: [
        {
          type: 'input',
          key: 'schoolid',
          name: '请输入学工号'
        },
        {
          type: 'input',
          key: 'name',
          name: '请输入姓名'
        },
        {
          type: 'input',
          key: 'username',
          name: '请输入账号'
        },
        {
          type: 'input',
          key: 'phone',
          name: '请输入手机号'
        },
        {
          type: 'input',
          key: 'email',
          name: '请输入邮箱'
        },
        {
          type: 'selectTreeSingle',
          useData: 'department',
          key: 'department',
          name: '所属单位'
        },
        {
          type: 'selectTreeSingle',
          useData: 'usertype',
          key: 'type',
          name: '人员类别'
        },
        {
          type: 'select',
          useData: 'isRegistered',
          key: 'registeredStatus',
          name: '注册状态'
        },
        {
          type: 'labelSelect',
          useData: [
            'idCardType',
            ''
          ],
          keys: [
            'idCardType',
            'idCardNumber'
          ]
        },
        {
          type: 'select',
          useData: 'atSchoolStatus',
          key: 'atSchoolStatus',
          name: '在校状态'
        },
        {
          type: 'select',
          useData: 'enabledStatus',
          key: 'enabledStatus',
          name: '启用状态'
        },
        {
          type: 'select',
          useData: 'isLocked',
          key: 'lockedStatus',
          name: '锁定状态'
        },
        {
          type: 'select',
          useData: 'tag',
          key: 'tag',
          name: '标签'
        },
        {
          type: 'select',
          useData: 'isEmpty',
          key: 'isEmptyUsername',
          name: '账号为空'
        },
        {
          type: 'select',
          useData: 'isEmpty',
          key: 'isEmptyPassword',
          name: '密码为空'
        },
        {
          type: 'select',
          useData: 'isEmpty',
          key: 'isEmptyPhone',
          name: '手机为空'
        },
        {
          type: 'select',
          useData: 'isEmpty',
          key: 'isEmptyEmail',
          name: '邮箱为空'
        },
        {
          type: 'file',
          useData: 'isLocked',
          key: 'lockedStatus',
          name: '模板查询'
        }
      ],
      isCache: false,
      searchFilters: {},
      filter: {
        current_page: 1,
        page_size: 10
      },
      count: 0,
      selectedRowKeys: [],
      tablecolumns: [
        {
          title: '学工号',
          dataIndex: 'schoolid',
          scopedSlots: { customRender: 'schoolid' },
          width: 100,
          fixed: 'left',
          disabled: true
        },
        {
          title: '姓名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          width: 115,
          fixed: 'left',
          disabled: true
        },
        {
          title: '注册状态',
          dataIndex: 'accountid',
          scopedSlots: { customRender: 'accountid' },
          width: 70
          // customCell: () => {
          //   return {
          //     style: {
          //       'width': '70px'
          //     }
          //   }
          // }
        },
        {
          title: '在校状态',
          dataIndex: 'atSchoolStatus',
          scopedSlots: { customRender: 'atSchoolStatus' },
          width: 70
        },
        {
          title: '启用状态',
          dataIndex: 'isDisabled',
          scopedSlots: { customRender: 'isDisabled' },
          width: 132
        },
        {
          title: '锁定状态',
          dataIndex: 'lockedStatus',
          scopedSlots: { customRender: 'lockedStatus' },
          width: 74
        },
        // {
        //   title: '密码状态',
        //   dataIndex: 'passwordStatus',
        //   scopedSlots: { customRender: 'passwordStatus' },
        //   width: 74
        // },
        {
          title: '人脸识别状态',
          dataIndex: 'faceDisabled',
          scopedSlots: { customRender: 'faceDisabled' },
          width: 95
        },
        {
          title: '证件号码',
          dataIndex: 'idCardNumber',
          // scopedSlots: { customRender: 'passwordStatus' },
          width: 162
        },
        // {
        //   title: '账号',
        //   dataIndex: 'username',
        //   width: 160
        // },
        {
          title: '所属单位',
          dataIndex: 'department',
          scopedSlots: { customRender: 'department' },
          // width: 140
          customCell: () => {
            return {
              style: {
                'max-width': '284px',
              }
            }
          }
        },
        {
          title: '人员类别',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          width: 116
          // customCell: () => {
          //   return {
          //     style: {
          //       'width': '116px',
          //     }
          //   }
          // }
        },
        {
          fixed: 'right',
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          // width: 235
          customCell: () => {
            return {
              style: {
                'min-width': '60px',
              }
            }
          },
          customHeaderCell: () => {
            return {
              style: {
                'min-width': '100px',
              }
            }
          },
          disabled: true
        }
      ],
      tablecolumnsOri: [
        {
          title: '学工号',
          dataIndex: 'schoolid',
          scopedSlots: { customRender: 'schoolid' },
          width: 100,
          fixed: 'left',
          disabled: true
        },
        {
          title: '姓名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          width: 115,
          fixed: 'left',
          disabled: true
        },
        {
          title: '注册状态',
          dataIndex: 'accountid',
          scopedSlots: { customRender: 'accountid' },
          width: 70
        },
        {
          title: '在校状态',
          dataIndex: 'atSchoolStatus',
          scopedSlots: { customRender: 'atSchoolStatus' },
          width: 70
        },
        {
          title: '启用状态',
          dataIndex: 'isDisabled',
          scopedSlots: { customRender: 'isDisabled' },
          width: 132
        },
        {
          title: '锁定状态',
          dataIndex: 'lockedStatus',
          scopedSlots: { customRender: 'lockedStatus' },
          width: 74
        },
        // {
        //   title: '密码状态',
        //   dataIndex: 'passwordStatus',
        //   scopedSlots: { customRender: 'passwordStatus' },
        //   width: 74
        // },
        {
          title: '人脸识别状态',
          dataIndex: 'faceDisabled',
          scopedSlots: { customRender: 'faceDisabled' },
          width: 95
        },
        {
          title: '证件号码',
          dataIndex: 'idCardNumber',
          // scopedSlots: { customRender: 'passwordStatus' },
          width: 162
        },
        // {
        //   title: '账号',
        //   dataIndex: 'username',
        //   width: 160
        // },
        {
          title: '所属单位',
          dataIndex: 'department',
          scopedSlots: { customRender: 'department' },
          // width: 140
          customCell: () => {
            return {
              style: {
                'max-width': '284px',
              }
            }
          }
        },
        {
          title: '人员类别',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          width: 116
          // customCell: () => {
          //   return {
          //     style: {
          //       'width': '116px',
          //     }
          //   }
          // }
        },
        {
          fixed: 'right',
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          // width: 235
          customCell: () => {
            return {
              style: {
                'min-width': '60px',
              }
            }
          },
          customHeaderCell: () => {
            return {
              style: {
                'min-width': '100px',
              }
            }
          },
          disabled: true
        }
      ],
      checkList: ['schoolid', 'name', 'accountid', 'atSchoolStatus', 'isDisabled', 'lockedStatus', 'faceDisabled', 'idCardNumber', 'department', 'type', 'action'],
      checkListOri: ['schoolid', 'name', 'accountid', 'atSchoolStatus', 'isDisabled', 'lockedStatus', 'faceDisabled', 'idCardNumber', 'department', 'type', 'action'],
      itemshowboxVsible: false,
      tabledata: [],
      mes: {
        permit: 0,
        limittime: 0,
        prohibit: 0
      },
      visible: false,
      modes: 0,
      form: {
        userImage: '',
        name: '',
        sex: undefined,
        schoolid: '',
        department: undefined,
        idCardType: undefined,
        idCardNumber: '',
        type: undefined,
        isDisabled: '0',
        faceDisabled: '0',
        tag: undefined,
        deferDate: '',
        modifyComment: '',
        username: '',
        password: '',
        phone: '',
        email: '',
        startDate: '',
        endDate: '',
        atSchoolStatus: undefined,
        political: undefined,
        operation: '',
        accounttotpid: null,
        staticTags: [],
        dynamicTags: [],
        attribute: {}
      },
      rules: {
        // name: [
        //   { required: true, message: '', trigger: 'blur' },
        //   { min: 3, max: 5, message: '', trigger: 'blur' },
        // ],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        password: [
          { required: false, min: 6, message: '密码最少为6位', trigger: 'blur' },
          { reuqired: false, validator: this.compareToFirstPasswordpattern, message: '密码不能包含中文', trigger: 'blur' }
        ],
        schoolid: [{ required: true, message: '请输入学工号', trigger: 'blur' }],
        idCardType: [{ required: true, message: '请选择证件类型', trigger: 'change' }],
        idCardNumber: [{ required: true, message: '请输入证件号码', trigger: 'blur' }],
        department: [{ required: true, message: '请选择所属单位', trigger: 'change' }],
        type: [{ required: true, message: '请选择人员类别', trigger: 'change' }],
        isDisabled: [{ required: true, message: '请选择启用状态', trigger: 'change' }],
        atSchoolStatus: [{ required: false, message: '请选择人员状态', trigger: 'change' }]
      },
      formCopy: {},
      formStatus: {},
      logVisible: false,
      logform: {
        ip: '',
        type: undefined,
        comments: '',
        date: []
        // startDate: '',
        // endDate: ''
      },
      logPage: {
        current_page: 1,
        page_size: 10
      },
      logCount: 0,
      logBarsStatus: false,
      logTableColumns: [
        {
          title: '序号',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: 70
        },
        {
          title: '登录IP',
          dataIndex: 'ip',
          width: 140
        },
        {
          title: '日志类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          width: 120
        },
        {
          title: '详情',
          dataIndex: 'msg',
          scopedSlots: { customRender: 'msg' },
          ellipsis: true
        },
        {
          title: '时间',
          dataIndex: 'date',
          width: 180
        }
      ],
      logTableData: [],
      authVisible: false,
      tipsVisible: false,
      tipsForm: {
        loginNormal: {
          authenticationSuccess: true,
          loginSuccess: []
        },
        accountChange: {
          modifyPassword: true,
          modifyPhone: true,
          modifyEmail: true
        },
        accountAbnormal: {
          bruteforceAttack: true,
          weakPassword: true
        }
      },
      loginTipsVisible: false,
      loginTipsTableHeader: [
        {
          title: '系统名称',
          dataIndex: 'servicename',
          key: 'servicename',
          scopedSlots: { customRender: 'servicename' },
        },

        {
          title: '是否提醒',
          dataIndex: 'selected',
          key: 'selected',
          scopedSlots: { customRender: 'selected' },
          width: '120px'
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: '80px'
        }
      ],
      loginTipsTableData: [],
      addSysVisible: false,
      showAddAccountBtn: true,
      deferDateVisible: false,
      deferForm: {
        deferDate: '',
        deferComment: ''
      },
      deferRules: {
        deferDate: [{ required: true, message: '请选择停用日期', trigger: 'change' }]
      },
      current_row: {},
      isBatchDefer: false,
      cascaderWidth: 0,
      listUserImage: '',
      userImageLoading: false,
      multifactorList: [],
      singleTip: undefined,
      auditVisible: false,
      auditform: {
        ip: '',
        type: undefined,
        comments: '',
        date: []
        // startDate: '',
        // endDate: ''
      },
      auditPage: {
        current_page: 1,
        page_size: 10
      },
      auditCount: 0,
      auditTableColumns: [
        {
          title: '序号',
          dataIndex: 'action1',
          scopedSlots: { customRender: 'action1' },
          width: 70
        },
        {
          title: '源IP',
          dataIndex: 'ip',
          // width: 140
          customCell: () => {
            return {
              style: {
                'min-width': '140px',
              }
            }
          }
        },
        {
          title: '操作人',
          dataIndex: 'operator',
          // width: 120
          customCell: () => {
            return {
              style: {
                'min-width': '120px',
              }
            }
          }
        },
        {
          title: '日志类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          // width: 120
          customCell: () => {
            return {
              style: {
                'min-width': '120px',
              }
            }
          }
        },
        // {
        //   title: '详情',
        //   dataIndex: 'commentsKey',
        //   scopedSlots: { customRender: 'commentsKey' },
        //   ellipsis: true
        // },
        {
          title: '时间',
          dataIndex: 'date',
          // width: 180
          customCell: () => {
            return {
              style: {
                'min-width': '180px',
              }
            }
          }
        },
        {
          title: '操作',
          fixed: 'right',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          // width: '110px'
          customCell: () => {
            return {
              style: {
                'min-width': '80px',
              }
            }
          }
        }
      ],
      auditTableData: [],
      auditBarsStatus: false,
      exportLoading: false,
      exportInterval: null,
      editLoading: false,
      notificationLoading: false,
      multifactorLoading: false,
      loginlogLoading: false,
      auditlogLoading: false,
      detailVisible: false,
      detail_current_row: {},
      showDetailImg: false,
      userImgChanged: false,
      dynamicOptions: [],
      staticOptions: [],
      attrTextVisible: false,
      attributeText: '',
      attributeTextCanEdit: false,
      attributeTextEnName: '',
      currentDate: ''
    }
  },
  watch: {
    'selectedRowKeys': function (val) {
      this.batchActions = {
        canModify: true,
        canDeleteAccount: true,
        canLockAccount: true,
        canUnLockAccount: true
      }
      let tabledata = []
      val.map(item2 => {
        this.tabledata.map(item => {
          if (item2 === item.id) {
            tabledata.push(item)
          }
        })
      })
      // console.log(tabledata)
      tabledata.map(item => {
        if (!item.canModify) {
          this.batchActions.canModify = false
        }
        if (!item.canAccountModify || !item.accountid) {
          this.batchActions.canDeleteAccount = false
        }
        if (!item.canAccountModify || !item.accountid || item.lockedStatus !== '未锁定') {
          this.batchActions.canLockAccount = false
        }
        if (!item.canAccountModify || !item.accountid || (item.lockedStatus !== '管理员锁定' && item.lockedStatus !== '风险锁定')) {
          this.batchActions.canUnLockAccount = false
        }
      })
    },
    'searchFilters': function (val) {
      // console.log('searchFilters', val)
      let hasQuery = false
      for (let key in val) {
        if (val[key]) {
          hasQuery = true
          break
        }
      }
      this.hasQuery = hasQuery
      this.filter.current_page = 1
      // this.filter = Object.assign({}, this.filter)
      this.getUserList()
    },
    'form.password': function (val) {
      this.checkStrong(val)
      if (val) {
        this.rules.password[0]['required'] = true
        this.rules.password[1]['required'] = true
      } else {
        this.rules.password[0]['required'] = false
        this.rules.password[1]['required'] = false
      }
    },
    'filter': function () {
      // this.getUserList({
      //   ...this.searchFilters,
      //   ...val
      // })
      this.getUserList()
    },
    'visible': function (val) {
      if (val) {
        this.overflowText = 'hidden'
      } else {
        this.overflowText = 'auto'
      }
    },
    'form': {
      handler (val) {
        if (!this.isAdd) {
          for (let key in val) {
            if (JSON.stringify(val[key]) !== JSON.stringify(this.formCopy[key])) {
              // if (key === 'department') {
              //   console.log(111, val[key])
              //   console.log(222, this.formCopy[key])
              // }
              this.formStatus[key] = true
            }
          }
          // console.log(this.formStatus)
        }
      },
      deep: true
    }
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      let selections = []
      if (this.hasQuery || this.batchid) {
        selections = [
          {
            key: 'all-data',
            text: '选择所有（' + this.count + '）',
            onSelect: () => {
              let keys = []
              this.tabledata.map(item => {
                keys.push(item.id)
              })
              this.selectedRowKeys = keys
              this.chooseAll = true
            },
          },
          {
            key: 'reverse',
            text: '反选当页',
            onSelect: () => {
              let keys = []
              this.tabledata.map(item => {
                if (this.selectedRowKeys.indexOf(item.id) < 0) {
                  keys.push(item.id)
                }
              })
              this.selectedRowKeys = keys
              this.chooseAll = false
            },
          },
          {
            key: 'clear',
            text: '清空已选',
            onSelect: () => {
              this.selectedRowKeys = []
              this.chooseAll = false
            },
          }
        ]
      } else {
        selections = [
          {
            key: 'reverse',
            text: '反选当页',
            onSelect: () => {
              let keys = []
              this.tabledata.map(item => {
                if (this.selectedRowKeys.indexOf(item.id) < 0) {
                  keys.push(item.id)
                }
              })
              this.selectedRowKeys = keys
              this.chooseAll = false
            },
          },
          {
            key: 'clear',
            text: '清空已选',
            onSelect: () => {
              this.selectedRowKeys = []
              this.chooseAll = false
            },
          }
        ]
      }
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: selections,
        onSelection: this.onSelection,
      };
    },
    ...mapGetters([
      'user',
      'userModify',
      'userAccountModify'
    ])
  },
  methods: {
    changeTime (date) {
      this.currentDate = ''
      // console.log('date', date)
      // if (new Date(date[1]) - new Date(date[0]) > 1000 * 60 * 60 * 24 * 10) {
      //   this.$message.error('查询区间最长半年')
      //   return false
      // }
    },
    calendarChange (date) {
      // console.log('calendarChange', date)
      this.currentDate = date[0]
    },
    disabledDate(current) {
      // Can not select days before today and today
      let time = 1000 * 60 * 60 * 24 * 180
      if (this.currentDate) {
        return new Date(this.currentDate) - new Date(current) > time || new Date(current) - new Date(this.currentDate) > time
      } else {
        return false
      }
    },
    onShowColumnChange (checkedValues) {
      // console.log(checkedValues)
      let columns = []
      for (let key in this.tablecolumnsOri) {
        if (checkedValues.indexOf(this.tablecolumnsOri[key]['dataIndex']) > -1) {
          columns.push(this.tablecolumnsOri[key])
        }
      }
      this.tablecolumns = JSON.parse(JSON.stringify(columns))
    },
    resetColumn () {
      this.tablecolumns = JSON.parse(JSON.stringify(this.tablecolumnsOri))
      this.checkList = JSON.parse(JSON.stringify(this.checkListOri))
    },
    attrTextClose () {
      this.attrTextVisible = false
    },
    attrTextOpen (name_en, canEdit) {
      this.attributeText = this.form.attribute[name_en]
      this.attributeTextCanEdit = canEdit
      this.attributeTextEnName = name_en
      this.attrTextVisible = true
    },
    async attrTextSave () {
      let form = {
        attribute: {},
        operation: 'modify'
      }
      form.attribute[this.attributeTextEnName] = this.attributeText
      let res = await User.userDetail(this.current_row.id, form)
      if (!res['code']) {
        this.form.attribute[this.attributeTextEnName] = this.attributeText
        this.$message.success('修改成功！')
        this.attrTextVisible = false
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    unbindTotp () {
      this.$confirm({
        title: '',
        content: '确定要解绑吗',
        onOk: async () => {
          let res = await User.unbindTotp(this.current_row.id)
          if (!res['code']) {
            this.$message.success('解绑成功！')
            this.form.accounttotpid = null
          } else {
            this.$message.error(this.errMsg[res['code']])
          }
        }
      })
    },
    showDetial (row) {
      this.detail_current_row = row
      this.detailVisible = true
    },
    detailClose () {
      this.detailVisible = false
    },
    compareToFirstPasswordpattern(rule, value, callback) {
      if (value && /.*[\u4e00-\u9fa5]+.*$/.test(value)) {
        callback('Two passwords that you enter is inconsistent!');
      } else {
        callback();
      }
    },
    goBatchSearch () {
      this.$router.push('/user/batchsearch')
    },
    checkStrong(sValue) {
      var modes = 0;
      //正则表达式验证符合要求的
      if (!sValue) return modes
      // if (sValue.length < 1) return modes
      if (sValue.length > 0) modes++
      // if (/\d/.test(sValue) && /[a-z]/.test(sValue)) modes++ //数字和小写
      // if (/[A-Z]/.test(sValue) && /\W/.test(sValue)) modes++ //大写和特殊字符
      // if (/\W/.test(sValue)) modes++ //特殊字符
      if (sValue.length >= 6) {
        let typeNum = 0
        if (/\d/.test(sValue)) typeNum++
        if (/[a-z]/.test(sValue)) typeNum++
        if (/[A-Z]/.test(sValue)) typeNum++
        if (/\W/.test(sValue)) typeNum++
        if (typeNum > 1) {
          modes++
        }
        if (typeNum > 2) {
          modes++
        }
      }

      this.modes = modes
    },
    getAuditPageMes (obj) {
      this.auditPage = {
        current_page: obj.pageNumber,
        page_size: obj.pageSize
      }
      this.getAuditList()
    },
    auditHandle () {
      var defaultDate = this.util.getDataPickerDefault()
      var newDate = [this.util.getDateString(new Date(defaultDate[0])), this.util.getDateString(new Date(defaultDate[1]))]
      this.auditform.date = newDate
      this.auditVisible = true
      this.getAuditList()
    },
    auditClose () {
      this.auditVisible = false
      this.auditform = {
        ip: '',
        type: undefined,
        comments: '',
        date: []
      }
      this.auditPage = {
        current_page: 1,
        page_size: 10
      }
    },
    async getAuditList () {
      this.auditlogLoading = true
      let form = JSON.parse(JSON.stringify(Object.assign(this.auditform, this.auditPage)))
      form['startDate'] = form.date && form.date[0] ? form.date[0] : ''
      form['endDate'] = form.date && form.date[1] ? form.date[1] : ''
      delete form['date']
      let res = await User.getAuditLog(this.current_row.id, form)
      this.auditlogLoading = false
      if (!res['code']) {
        this.auditTableData = res['data']
        this.auditCount = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    deleteSuccessTips (row) {
      let index = this.tipsForm.loginNormal.loginSuccess.findIndex(item => {return item.serviceid === row.serviceid})
      this.tipsForm.loginNormal.loginSuccess.splice(index, 1)
    },
    getLoginPageMes (obj) {
      this.logPage = {
        current_page: obj.pageNumber,
        page_size: obj.pageSize
      }
      this.getLoginLogList()
    },
    getLoginLogListHandle () {
      this.logPage.current_page = 1
      this.getLoginLogList()
    },
    getAuditLogListHandle () {
      this.auditPage.current_page = 1
      this.getAuditList()
    },
    async getLoginLogList () {
      this.loginlogLoading = true
      let form = JSON.parse(JSON.stringify(Object.assign(this.logform, this.logPage)))
      form['msg'] = '%' + form['comments'] + '%'
      form['startDate'] = form.date && form.date[0] ? form.date[0] : ''
      form['endDate'] = form.date && form.date[1] ? form.date[1] : ''
      delete form['date']
      let res = await User.getUserLoginLog(this.current_row.id, form)
      this.loginlogLoading = false
      if (!res['code']) {
        this.logTableData = res['data']
        this.logCount = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async visibleChange (visible, row) {
      if (visible) {
        this.userImageLoading = true
        let res = await User.getUserImage(row.id)
        this.userImageLoading = false
        if (!res['code']) {
          this.listUserImage = 'data:image/jpeg;base64,' + res['data']
        } else {
          this.$message.error(this.errMsg[res['code']])
        }
      } else {
        this.listUserImage = ''
      }
    },
    addDepartment () {
      this.form.department.push('')
    },
    deleteDepartment (index) {
      this.form.department.split(index, 1)
    },
    goBatchAccount () {
      this.$router.push('/user/batchaccount')
    },
    goBatchAccountModify () {
      this.$router.push('/user/batchaccountmodify')
    },
    goBatchUserAccount () {
      this.$router.push('/user/batchuseraccount')
    },
    goBatchUserAccountModify () {
      this.$router.push('/user/batchuseraccountmodify')
    },
    goBatchUser () {
      this.$router.push('/user/batchuser')
    },
    goBatchUserModify () {
      this.$router.push('/user/batchusermodify')
    },
    addSysHandle () {
      this.addSysVisible = true
    },
    addSysClose () {
      if (!this.singleTip) {
        this.$message.error('请选择系统！')
        return false
      }
      let isHas = this.tipsForm.loginNormal.loginSuccess.find(item => {return item.serviceid === this.singleTip})
      if (isHas) {
        this.$message.error('此系统已存在！')
        return false
      }
      let item = this.filters['service'].find(item => {return item.id === this.singleTip})
      let newItem = {
        servicename: item.name,
        serviceid: item.id,
        selected: true,
        required: false
      }
      this.tipsForm.loginNormal.loginSuccess.unshift(newItem)
      this.addSysVisible = false
      this.singleTip = undefined
    },
    setloginTips () {
      this.loginTipsVisible = true
    },
    loginTipsClose () {
      this.loginTipsVisible = false
    },
    async checkTips () {
      this.tipsVisible = true
      let res = await User.getUserNotification(this.current_row.id)
      if (!res['code']) {
        this.tipsForm = res['data'] || {
        loginNormal: {
          authenticationSuccess: true,
          loginSuccess: []
        },
        accountChange: {
          modifyPassword: true,
          modifyPhone: true,
          modifyEmail: true
        },
        accountAbnormal: {
          bruteforceAttack: true,
          weakPassword: true
        }
      }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async tipsClose () {
      this.notificationLoading = true
      let res = await User.putUserNotification(this.current_row.id, this.tipsForm)
      this.notificationLoading = false
      if (!res['code']) {
        this.$message.success('保存成功！')
        this.tipsVisible = false
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async checkAuth () {
      this.authVisible = true
      this.multifactorLoading = true
      let res = await User.getUserMultifactor(this.current_row.id)
      this.multifactorLoading = false
      if (!res['code']) {
        let array = []
        res['data'].map(item => {
          item['selected'] = item['selected'] === 'N' ? false : true
          array.push(item)
        })
        this.multifactorList = array
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async authClose () {
      let arr = []
      this.multifactorList.map(item => {
        let newItem = JSON.parse(JSON.stringify(item))
        newItem['selected'] = newItem['selected'] ? 'Y' : 'N'
        arr.push(newItem)
      })
      this.multifactorLoading = true
      let res = await User.putUserMultifactor(this.current_row.id, arr)
      this.multifactorLoading = false
      if (!res['code']) {
        this.$message.success('保存成功！')
        this.authVisible = false
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    checkLog () {
      var defaultDate = this.util.getDataPickerDefault()
      var newDate = [this.util.getDateString(new Date()) + ' 00:00:00', this.util.getDateString(new Date()) + ' 23:59:59']
      this.logform.date = newDate
      this.logVisible = true
      this.getLoginLogList()
    },
    logReset () {
      this.$refs.logForm.resetFields()
    },
    auditlogReset () {
      this.$refs.auditForm.resetFields()
    },
    logClose () {
      this.logVisible = false
      this.logform = {
        ip: '',
        type: undefined,
        comments: '',
        date: []
      }
      this.logPage = {
        current_page: 1,
        page_size: 10
      }
    },
    deferDateClose () {
      this.deferDateVisible = false
      this.deferForm = {
        deferDate: '',
        deferComment: ''
      }
    },
    async deferDateHandle (row) {
      this.isBatchDefer = false
      this.current_row = row
      this.deferDateVisible = true
      this.deferForm.deferComment = row.modifyComment
    },
    async deferDateSubmit () {
      this.$refs['deferRefForm'].validate( async validate => {
        if (validate) {
          this.loading = true
          if (this.isBatchDefer) {
            let form = {
              userid: this.selectedRowKeys,
              deferDate: this.deferForm.deferDate,
              modifyComment: this.deferForm.deferComment,
              operation: 'modify_limittime'
            }
            // console.log('this.batchid', this.batchid)
            // console.log('this.chooseAll', this.chooseAll)
            // console.log('this.selectedRowKeys.length', this.selectedRowKeys.length)
            if (this.batchid && this.selectedRowKeys.length === this.tabledata.length && this.chooseAll) {
              form['batchid'] = this.batchid
              delete form['userid']
            }
            if (!this.batchid && this.selectedRowKeys.length === this.tabledata.length && this.chooseAll) {
              let obj = {
                query: 'datas'
              }
              form = Object.assign(obj, this.searchFilters, form)
              delete form['userid']
            }
            let res = await User.userBatchModify(form)
            if (!res['code']) {
              this.$message.success('操作成功！')
              this.deferDateClose()
              this.getUserList()
            } else {
              this.$message.error(this.errMsg[res['code']])
            }
          } else {
            let res = await User.userDetail(this.current_row.id, {
              deferDate: this.deferForm.deferDate,
              modifyComment: this.deferForm.deferComment,
              operation: 'modify_limittime'
            })
            if (!res['code']) {
              this.$message.success('操作成功！')
              this.deferDateClose()
              this.getUserList()
            } else {
              this.$message.error(this.errMsg[res['code']])
            }
          }
        }
      })
    },
    async disabledOn (row) {
      this.loading = true
      let res = await User.userDetail(row.id, {
        isDisabled: '0',
        operation: 'modify_permit'
      })
      if (!res['code']) {
        this.$message.success('启用成功！')
        this.getUserList()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async disabledOff (row) {
      this.loading = true
      let res = await User.userDetail(row.id, {
        isDisabled: '1',
        operation: 'modify_prohibit'
      })
      if (!res['code']) {
        this.$message.success('操作成功！')
        this.getUserList()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    getSearch (filter) {
      console.log(111, filter)
      this.isCache = false
      this.$cookie.delete('batchid')
      this.batchid = ''
      this.selectedRowKeys = []
      this.searchFilters = Object.assign({}, filter)
      this.chooseAll = false
      this.onClose()
    },
    getSearchCache (filter) {
      // console.log(filter)
      if (this.baseConfig.userSearchCache && !this.batchid) {
        this.isCache = true
        this.$cookie.delete('batchid')
        this.batchid = ''
        this.selectedRowKeys = []
        this.searchFilters = Object.assign({}, filter)
        this.chooseAll = false
        this.onClose()
      }
      this.status = true
    },
    // handleMenuClick (e) {
    //   // console.log('click', e)
    // },
    moreHandle (e, row) {
      if (e.key === '1') { // 开通
        this.editHandle(row)
      }
      if (e.key === '2') { // 锁定
        this.lockOn(row)
      }
      if (e.key === '3') { // 解锁
        this.lockOff(row)
      }
      if (e.key === '4') { // 删除学工号
        this.deleteAccount(row)
      }
      if (e.key === '5') { // 删除人员
        this.deleteUser(row)
      }
    },
    async deleteAccount (row) {
      this.$confirm({
        title: '',
        content: '确定要删除吗',
        onOk: async () => {
          this.loading = true
          let res = await User.userBatchRemove({
            userid: [row.id],
            operation: 'remove_account'
          })
          this.loading = false
          if (!res['code']) {
            this.$message.success('删除成功！')
            this.getUserList()
          } else {
            this.$message.error(this.errMsg[res['code']])
          }
        }
      })
    },
    async deleteUser (row) {
      this.$confirm({
        title: '',
        content: '确定要删除吗',
        onOk: async () => {
          this.loading = true
          let res = await User.userBatchRemove({
            userid: [row.id],
            operation: 'remove_user'
          })
          this.loading = false
          if (!res['code']) {
            this.$message.success('删除成功！')
            this.getUserList()
          } else {
            this.$message.error(this.errMsg[res['code']])
          }
        }
      })
    },
    async lockOn (row) {
      this.loading = true
      let res = await User.userDetail(row.id, {
        operation: 'modify_lock'
      })
      if (!res['code']) {
        this.$message.success('锁定成功！')
        this.getUserList()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async lockOff (row) {
      this.loading = true
      let res = await User.userDetail(row.id, {
        operation: 'modify_unlock'
      })
      if (!res['code']) {
        this.$message.success('解锁成功！')
        this.getUserList()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async batchUserHandle (e) {
      // console.log(this.selectedRowKeys)
      if (!this.selectedRowKeys.length) {
        this.$message.error('请先选择人员!')
        return false
      }
      if (e.key === '1') { // 启用
        let form = {
          userid: this.selectedRowKeys,
          operation: 'modify_permit'
        }
        if (this.batchid && this.selectedRowKeys.length === this.tabledata.length && this.chooseAll) {
          form['batchid'] = this.batchid
          delete form['userid']
        }
        if (!this.batchid && this.selectedRowKeys.length === this.tabledata.length && this.chooseAll) {
          let obj = {
            query: 'datas'
          }
          form = Object.assign(obj, this.searchFilters, form)
          delete form['userid']
        }
        let res = await User.userBatchModify(form)
        if (!res['code']) {
          this.$message.success('启用成功！')
          this.getUserList()
        } else {
          this.$message.error(this.errMsg[res['code']])
        }
      }
      if (e.key === '2') { // 停用
        let form = {
          userid: this.selectedRowKeys,
          operation: 'modify_prohibit'
        }
        if (this.batchid && this.selectedRowKeys.length === this.tabledata.length && this.chooseAll) {
          form['batchid'] = this.batchid
          delete form['userid']
        }
        if (!this.batchid && this.selectedRowKeys.length === this.tabledata.length && this.chooseAll) {
          let obj = {
            query: 'datas'
          }
          form = Object.assign(obj, this.searchFilters, form)
          delete form['userid']
        }
        let res = await User.userBatchModify(form)
        if (!res['code']) {
          this.$message.success('操作成功！')
          this.getUserList()
        } else {
          this.$message.error(this.errMsg[res['code']])
        }
      }
      if (e.key === '3') { // 定期停用
        this.isBatchDefer = true
        this.deferDateVisible = true
      }
      if (e.key === '4') { // 锁定
        let form = {
          userid: this.selectedRowKeys,
          operation: 'modify_lock'
        }
        if (this.batchid && this.selectedRowKeys.length === this.tabledata.length && this.chooseAll) {
          form['batchid'] = this.batchid
          delete form['userid']
        }
        if (!this.batchid && this.selectedRowKeys.length === this.tabledata.length && this.chooseAll) {
          let obj = {
            query: 'datas'
          }
          form = Object.assign(obj, this.searchFilters, form)
          delete form['userid']
        }
        let res = await User.userBatchModify(form)
        if (!res['code']) {
          this.$message.success('锁定成功！')
          this.getUserList()
        } else {
          this.$message.error(this.errMsg[res['code']])
        }
      }
      if (e.key === '5') { // 解锁
        let form = {
          userid: this.selectedRowKeys,
          operation: 'modify_unlock'
        }
        if (this.batchid && this.selectedRowKeys.length === this.tabledata.length && this.chooseAll) {
          form['batchid'] = this.batchid
          delete form['userid']
        }
        if (!this.batchid && this.selectedRowKeys.length === this.tabledata.length && this.chooseAll) {
          let obj = {
            query: 'datas'
          }
          form = Object.assign(obj, this.searchFilters, form)
          delete form['userid']
        }
        let res = await User.userBatchModify(form)
        if (!res['code']) {
          this.$message.success('解锁成功！')
          this.getUserList()
        } else {
          this.$message.error(this.errMsg[res['code']])
        }
      }
      if (e.key === '6') { // 删除学工号
        this.$confirm({
          title: '',
          content: '确定要删除吗',
          onOk: async () => {
            let form = {
              userid: this.selectedRowKeys,
              operation: 'remove_account'
            }
            if (this.batchid && this.selectedRowKeys.length === this.tabledata.length && this.chooseAll) {
              form['batchid'] = this.batchid
              delete form['userid']
            }
            if (!this.batchid && this.selectedRowKeys.length === this.tabledata.length && this.chooseAll) {
              let obj = {
                query: 'datas'
              }
              form = Object.assign(obj, this.searchFilters, form)
              delete form['userid']
            }
            let res = await User.userBatchRemove(form)
            if (!res['code']) {
              this.$message.success('删除成功！')
              this.getUserList()
            } else {
              this.$message.error(this.errMsg[res['code']])
            }
          }
        })
      }
      if (e.key === '7') { // 删除人员
        this.$confirm({
          title: '',
          content: '确定要删除吗',
          onOk: async () => {
            let form = {
              userid: this.selectedRowKeys,
              operation: 'remove_user'
            }
            if (this.batchid && this.selectedRowKeys.length === this.tabledata.length && this.chooseAll) {
              form['batchid'] = this.batchid
              delete form['userid']
            }
            if (!this.batchid && this.selectedRowKeys.length === this.tabledata.length && this.chooseAll) {
              let obj = {
                query: 'datas'
              }
              form = Object.assign(obj, this.searchFilters, form)
              delete form['userid']
            }
            let res = await User.userBatchRemove(form)
            if (!res['code']) {
              this.$message.success('删除成功！')
              this.getUserList()
            } else {
              this.$message.error(this.errMsg[res['code']])
            }
          }
        })
      }
      if (e.key === '8') { // 开通账号
        this.$confirm({
          title: '',
          content: '确定要开通账号吗',
          onOk: async () => {
            let form = {
              userid: this.selectedRowKeys,
              operation: 'modify_password'
            }
            if (this.batchid && this.selectedRowKeys.length === this.tabledata.length && this.chooseAll) {
              form['batchid'] = this.batchid
              delete form['userid']
            }
            if (!this.batchid && this.selectedRowKeys.length === this.tabledata.length && this.chooseAll) {
              let obj = {
                query: 'datas'
              }
              form = Object.assign(obj, this.searchFilters, form)
              delete form['userid']
            }
            let res = await User.userBatchModify(form)
            if (!res['code']) {
              this.$message.success('开通成功！')
              this.getUserList()
            } else {
              this.$message.error(this.errMsg[res['code']])
            }
          }
        })
      }
    },
    onSelectChange(selectedRowKeys) {
      // console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    getPageMes (obj) {
      this.filter = {
        current_page: obj.pageNumber,
        page_size: obj.pageSize
      }
    },
    async submitSave () {
      this.$refs['ruleForm'].validate( async validate => {
        if (validate) {
          this.editLoading = true
          let res1 = await Auth.getIndexKey()
          if (!res1['code']) {
            let form = JSON.parse(JSON.stringify(this.form))
            form['operation'] = 'add'
            let res = null
            if (form.userImage) {
              form.userImage = form.userImage.split(',')[1]
            }
            var newFormNew = JSON.parse(JSON.stringify(form))
            if (newFormNew['password']) {
              var encrypted = sm2.encrypt(newFormNew['password'], res1.data)
              newFormNew['password'] = encrypted
            }
            if (!this.isAdd) {
              if (!this.userImgChanged) {
                delete newFormNew['userImage']
              }
              newFormNew['accountid'] = this.current_row.accountid
              let newForm = {}
              for (let key in newFormNew) {
                if (this.formStatus[key]) {
                  newForm[key] = newFormNew[key]
                }
                if (!newForm['password']) {
                  delete newForm['password']
                }
              }
              newForm['operation'] = 'modify'
              res = await User.userDetail(this.current_row.id, newForm)
            } else {
              res = await User.userAdd(newFormNew)
            }
            if (!res['code']) {
              this.$message.success('保存成功！')
              this.onClose()
              this.getUserList()
            } else {
              this.$message.error(this.errMsg[res['code']])
            }
          } else {
            this.$message.error(this.errMsg[res1['code']])
          }
          this.editLoading = false
        }
      })
    },
    async userExportHandle () {
      this.exportLoading = true
      let searchFilters = JSON.parse(JSON.stringify(this.searchFilters))
      if (this.batchid) {
        searchFilters = {
          batchid: this.batchid
        }
      }
      if (this.selectedRowKeys.length && !this.chooseAll) {
        searchFilters['selectedid'] = this.selectedRowKeys.join()
        delete searchFilters['batchid']
      }
      let res = await  User.getUserExport(searchFilters)
      if (!res['code']) {
        this.exportInterval = setInterval(() => {
          this.exportDownloadStatus(res['data'])
        }, 1000)
        // window.open(this.config.api + 'user/export/download?taskid=' + res['data'], '_blank')
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async exportDownloadStatus (id) {
      let res = await User.exportDownloadStatus({
        taskid: id
      })
      if (!res['code']) {
        if (res['data'] === 'success') {
          clearInterval(this.exportInterval)
          this.exportDownload(id)
        }
        if (res['data'] === 'error') {
          this.exportLoading = false
          clearInterval(this.exportInterval)
          this.$message.error('处理失败，请重试！');
        }
      } else {
        this.exportLoading = false
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async exportDownload (id) {
      let res = await User.exportDownload(id)
      if (!res['description']) {
        this.exportLoading = false
        window.open(this.config.api + 'user/export/download?taskid=' + id, '_blank')
        this.getUserList()
      }
    },
    async getUserList () {
      // console.log(this.baseConfig)
      this.loading = true
      let obj = {
        query: this.isCache ? 'cache' : 'datas'
      }
      let form = Object.assign(obj, this.searchFilters, this.filter)
      if (this.batchid) {
        form['batchid'] = this.batchid
      }
      let res = await User.getUserList(form)
      if (!res['code']) {
        let tabledata = []
        res['data'].map(item => {
          if (!item['attribute']) {
            item['attribute'] = {}
          }
          for (let key in this.filters['attribute']) {
            if (!item['attribute'][this.filters['attribute'][key]['name_en']]) {
              item['attribute'][this.filters['attribute'][key]['name_en']] = ''
            }
          }
          tabledata.push(this.parseUserAuth(item))
        })
        this.tabledata = tabledata
        this.count = res['total']
        this.selectedRowKeys = []
        this.chooseAll = false
        // console.log(this.tabledata)
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
      if (this.isCache) {
        this.loading = false
        return false
      }
      let form2 = Object.assign(obj, this.searchFilters)
      let res1 = await User.getUserCount(form2)
      this.loading = false
      if (!res1['code']) {
        this.mes = res1['data'] || {}
      } else {
        // this.$message.error(res1['description'])
        this.$message.error(this.errMsg[res1['code']])
      }
    },
    parseUserAuth (item) {
      let newItem = JSON.parse(JSON.stringify(item))
      for (let key in this.user['privilege']['user']) {
        let ruleItem = this.user['privilege']['user'][key]
        if (ruleItem.privilege === '*') {
          newItem['canModify'] = true
          newItem['canAccountQuery'] = true
          newItem['canAccountModify'] = true
          return newItem
        } else {
          if (this.isIdentity(newItem, ruleItem)) {
            for (let k in ruleItem['privilege']) {
              if (ruleItem['privilege'][k][0] === 'modify') {
                newItem['canModify'] = true
              }
              if (ruleItem['privilege'][k][0] === 'account/query') {
                newItem['canAccountQuery'] = true
              }
              if (ruleItem['privilege'][k][0] === 'account/modify') {
                newItem['canAccountModify'] = true
              }
            }
          }
        }
      }
      return newItem
    },
    isIdentity (user, rule) {
      let isDepart = false
      let isUsertype = false
      if (user['department'] && user['department'].length) {
        for (let key in user['department']) {
          if (this.departmentTreeData[rule['scope']['depart']] && this.departmentTreeData[rule['scope']['depart']].indexOf(user['department'][key]) > -1) {
            isDepart = true
            break
          }
        }
      }
      if (user['type']) {
        for (let key in rule['scope']['type']) {
          if (this.usertypeTreeData[rule['scope']['type'][key]] && this.usertypeTreeData[rule['scope']['type'][key]].indexOf(user['type']) > -1) {
            isUsertype = true
            break
          }
        }
      }
      return isDepart && isUsertype
    },
    showAddAccountHandle () {
      this.showAddAccountBtn = false
      this.rules.password[0]['required'] = true
    },
    async editHandle (row) {
      // console.log(row)
      this.editLoading = true
      this.current_row = JSON.parse(JSON.stringify(row))
      this.visible = true
      let res = await User.getUserDetail(row.id)
      this.editLoading = false
      if (!res['code']) {
        var department = undefined
        if (res['data']['department'].length) {
          // res['data']['department'].map(item => {
          //   department.push([item])
          // })
          department = res['data']['department']
        }
        //  else {
        //   department = [undefined]
        // }
        this.current_row['lastLoginDate'] = res['data']['lastLoginDate'] || ''
        let attribute = {}
        if (!res['data']['attribute']) {
          res['data']['attribute'] = {}
        }
        for (let key in this.filters['attribute']) {
          attribute[this.filters['attribute'][key]['name_en']] = ''
          for (let key1 in res['data']['attribute']) {
            if (this.filters['attribute'][key]['name_en'] === key1) {
              attribute[this.filters['attribute'][key]['name_en']] = res['data']['attribute'][key1]
            }
          }
        }
        this.form = {
          userImage: res['data']['userImage'] ? 'data:image/jpeg;base64,' + res['data']['userImage'] : '',
          name: res['data']['name'],
          sex: res['data']['sex'] || undefined,
          schoolid: res['data']['schoolid'],
          department: department,
          idCardType: res['data']['idCardType'],
          idCardNumber: res['data']['idCardNumber'],
          type: res['data']['type'],
          isDisabled: res['data']['isDisabled'],
          faceDisabled: res['data']['faceDisabled'],
          tag: undefined,
          deferDate: res['data']['deferDate'],
          modifyComment: res['data']['modifyComment'],
          username: res['data']['username'],
          password: '',
          phone: res['data']['phone'],
          email: res['data']['email'],
          startDate: res['data']['startDate'],
          endDate: res['data']['endDate'],
          atSchoolStatus: res['data']['atSchoolStatus'] || undefined,
          political: res['data']['political'] || undefined,
          operation: '',
          accounttotpid: res['data']['accounttotpid'],
          staticTags: res['data']['staticTags'],
          dynamicTags: res['data']['dynamicTags'],
          attribute: attribute
        }
        let formStatus = {}
        for (let key in this.form) {
          formStatus[key] = false
        }
        this.formStatus = formStatus
        this.formCopy = JSON.parse(JSON.stringify(this.form))
        setTimeout(() => {
          this.isAdd = false
        }, 500)
        if (res['data']['registeredStatus'] === '1') {
          this.showAddAccountBtn = false
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    addUser () {
      this.isAdd = true
      this.visible = true
    },
    onClose () {
      this.userImgChanged = false
      this.rules.password[0]['required'] = false
      this.visible = false
      let attribute = {}
      for (let key in this.filters['attribute']) {
        attribute[this.filters['attribute'][key]['name_en']] = ''
      }
      this.form = {
        userImage: '',
        name: '',
        sex: undefined,
        schoolid: '',
        department: undefined,
        idCardType: undefined,
        idCardNumber: '',
        type: undefined,
        isDisabled: '0',
        faceDisabled: '0',
        tag: undefined,
        deferDate: '',
        modifyComment: '',
        username: '',
        password: '',
        phone: '',
        email: '',
        startDate: '',
        endDate: '',
        atSchoolStatus: undefined,
        political: undefined,
        operation: '',
        staticTags: [],
        dynamicTags: [],
        attribute: attribute
      }
      this.logPage = {
        current_page: 1,
        page_size: 10
      }
      this.logPage = {
        current_page: 1,
        page_size: 10
      }
      this.modes = 0
      this.showAddAccountBtn = true
      this.$refs['ruleForm'].resetFields()
      this.showDetailImg = false
    },
    // ImageToBase64 () {
    //   let files = document.getElementById('upImageFile').files[0];
    //   var reader = new FileReader()
    //   reader.readAsDataURL(files)
    //   reader.onload = () => {
    //     console.log('file 转 base64结果：' + reader.result)
    //     this.iconBase64 = reader.result
    //   }
    //   reader.onerror = function (error) {
    //     console.log('Error: ', error)
    //   }
    // },
    transformFile (file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        // console.log('file 转 base64结果：' + reader.result)
        this.userImgChanged = true
        this.form.userImage = reader.result
        this.showDetailImg = true
      }
      reader.onerror = (error) => {
        // console.log('Error: ', error)
        this.$message.error(error)
      }
    },
    // handleChange (info) {
    //   if (info.file.status !== 'uploading') {
    //     console.log(info.file, info.fileList);
    //   }
    //   if (info.file.status === 'done') {
    //     this.$message.success(`${info.file.name} file uploaded successfully`);
    //     this.form.userImage = info.file.response.url
    //   } else if (info.file.status === 'error') {
    //     this.$message.error(`${info.file.name} file upload failed.`);
    //   }
    // },
    trunDepartmentData (data) {
      for (let k in data) {
        this.departmentName[data[k]['id']] = data[k]['name']
        if (data[k]['children']) {
          this.trunDepartmentData(data[k]['children'])
        }
      }
    },
    trunUsertypeData (data) {
      for (let k in data) {
        this.usertypeName[data[k]['id']] = data[k]['name']
        if (data[k]['children']) {
          this.trunUsertypeData(data[k]['children'])
        }
      }
    },
    getChildrenParseData (key, data, dataKey) {
      for (let k in data) {
        this[dataKey][key].push(data[k]['id'])
        if (data[k]['children']) {
          this.getChildrenParseData(key, data[k]['children'], dataKey)
        }
      }
    },
    getChildrenData (key, data, dataKey) {
      this.getChildrenParseData(key, data, dataKey)
    },
    getItemChildren (id, data, dataKey) {
      for (let key in data) {
        if (data[key]['id'] === id) {
          if (data[key]['children']) {
            this.getChildrenData(id, data[key]['children'], dataKey)
          }
        } else {
          if (data[key]['children']) {
            this.getItemChildren(id, data[key]['children'], dataKey)
          }
        }
      }
    },
    getTreeData (data, dataKey) {
      for (let key in data) {
        if (!this[dataKey][data[key]['id']]) {
          this[dataKey][data[key]['id']] = [data[key]['id']]
        }
        if (data[key]['children']) {
          this.getTreeData(data[key]['children'], dataKey)
        }
        this.getItemChildren(data[key]['id'], data, dataKey)
      }
    },
    async getCode () {
      let res1 = await Filter.getCode('department')
      this.filters['department'] = res1['data']
      this.trunDepartmentData(res1['data'])
      this.getTreeData(res1['data'], 'departmentTreeData')
      // console.log('departmentTreeData', this.departmentTreeData)
      let res2 = await Filter.getCode('usertype')
      this.filters['usertype'] = res2['data']
      this.trunUsertypeData(res2['data'])
      this.getTreeData(res2['data'], 'usertypeTreeData')
      // console.log('usertypeTreeData', this.usertypeTreeData)

      if (this.baseConfig.initUserData) {
        this.getUserList()
      }

      let res3 = await Filter.getCode('role')
      this.filters['roles'] = res3['data']
      let res6 = await Filter.getCode('service')
      this.filters['service'] = res6['data']
      let res4 = await Filter.getCode('basic')
      this.filters['idCardType'] = res4['data']['id']
      this.filters['isDisabled'] = res4['data']['isDisabled']
      let desabledOptions = []
      res4['data']['isDisabled'].map(item => {
        desabledOptions.push({
          label: item.name,
          value: item.id
        })
      })
      this.filters['isDisabled2'] = desabledOptions
      this.filters['isDisabled3'] = [
        {
          label: '启用',
          value: '1'
        },
        {
          label: '停用',
          value: '0'
        }
      ]
      this.filters['atSchoolStatus'] = res4['data']['atSchoolStatus']
      this.filters['isRegistered'] = res4['data']['isRegistered']
      this.filters['isLocked'] = res4['data']['isLocked']
      this.filters['gender'] = res4['data']['gender']
      this.filters['political'] = res4['data']['political']
      this.filters['operateType'] = res4['data']['operateType']
      this.filters = Object.assign({}, this.filters)
      let res5 = await Filter.getCode('auditlog')
      this.filters['auditlog'] = res5['data']
      this.filters['loginlog'] = [{"id":"authN","name":"认证"},{"id":"authZ","name":"登录"}]

      let res7 = await Filter.getCode('tag')
      this.filters['tag'] = res7['data']
      let dynamicOptions = []
      let staticOptions = []
      res7['data'].map(item => {
        if (item.type === 'dynamic') {
          dynamicOptions.push(item)
        } else if (item.type === 'static') {
          item.id = String(item.id)
          staticOptions.push(item)
        }
      })
      this.dynamicOptions = dynamicOptions
      this.staticOptions = staticOptions

      let res8 = await Filter.getCode('attribute')
      this.filters['attribute'] = res8['data']
      let res9 = await Filter.getCode('attributetype')
      this.filters['attributetype'] = res9['data']
    },
    downloadByBlob(fileName, text) {
        let a = document.createElement("a");
        a.href = URL.createObjectURL(new Blob([text], {type: "application/octet-stream"}));
        a.download = fileName || 'Blob导出测试.txt';
        a.click();
        a.remove();
        URL.revokeObjectURL(a.href);
    },
    exportExcel(fileName,columns,datas){
        //列名
        let columnHtml = "";
        columnHtml += "<tr style=\"text-align: center;\">\n";
        for (let key in columns) {
            columnHtml += "<td style=\"background-color:#bad5fd\">"+columns[key]+"</td>\n";
        }
        columnHtml += "</tr>\n";

        //数据
        let dataHtml = "";
        for (let data of datas) {
            dataHtml += "<tr style=\"text-align: center;\">\n";
            for (let key in columns) {
                dataHtml += "<td>"+data[key]+"</td>\n";
            }
            dataHtml += "</tr>\n";
        }

        //完整html
        let excelHtml = "<html xmlns:o=\"urn:schemas-microsoft-com:office:office\"\n" +
            "      xmlns:x=\"urn:schemas-microsoft-com:office:excel\"\n" +
            "      xmlns=\"http://www.w3.org/TR/REC-html40\">\n" +
            "<head>\n" +
            "   <!-- 加这个，其他单元格带边框 -->" +
            "   <xml>\n" +
            "        <x:ExcelWorkbook>\n" +
            "            <x:ExcelWorksheets>\n" +
            "                <x:ExcelWorksheet>\n" +
            "                    <x:Name></x:Name>\n" +
            "                    <x:WorksheetOptions>\n" +
            "                        <x:DisplayGridlines/>\n" +
            "                    </x:WorksheetOptions>\n" +
            "                </x:ExcelWorksheet>\n" +
            "            </x:ExcelWorksheets>\n" +
            "        </x:ExcelWorkbook>\n" +
            "   </xml>\n" +
            "   <style>td{font-family: \"宋体\";}</style>\n" +
            "</head>\n" +
            "<body>\n" +
            "<table border=\"1\">\n" +
            "    <thead>\n" +
            columnHtml +
            "    </thead>\n" +
            "    <tbody>\n" +
            dataHtml +
            "    </tbody>\n" +
            "</table>\n" +
            "</body>\n" +
            "</html>";

        //下载
        this.downloadByBlob((fileName || "导出Excel")+".xls",excelHtml);
    }
  },
  created () {
    this.batchid = this.$cookie.get('batchid')
    // console.log('this.batchid', this.batchid)
    this.getCode()
    if (this.batchid) {
      this.getUserList()
    }
    // this.exportExcel("xx业务Excel导出", {"id": "编号", "name": "名字", "age": "年龄", "time": "参加工作时间"}, [{
    //     "id": "A001",
    //     "name": "张三",
    //     "age": "18",
    //     "time": new Date().toLocaleString()
    // },{
    //     "id": "A002",
    //     "name": "李四",
    //     "age": "20",
    //     "time": new Date().toLocaleString()
    // }])
  },
  mounted () {
    this.cascaderWidth = document.body.clientWidth - 1020
  }
};
</script>

<style scoped>
.content-bar {
  padding: 7px 0;
}

.drawer-title {
  /* padding: 20px 0; */
  margin-left: 10px;
}
.drawer-title span {
  margin-right: 20px;
}
.drawer-title a {
  text-decoration: underline;
}
.drawer-bar-bot {
  padding: 20px 0;
}

.poster-box {
  width:102px;
  height:144px;
  background: #FFFFFF;
  border: 1px dashed rgba(0,0,0,0.15);
  border-radius: 2px;
  border-radius: 2px;
  position: relative;
  text-align:center;
  float: left;
  position: relative;
}
.poster-box img {
  width:125px;
  height:176px;
}
.poster-box span {
  color: #ddd;
  line-height: 20px;
  margin: 3px auto 0;
}
.poster-wrap-cover {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width:100%;
  height: 100%;
}
.poster-box:hover .poster-wrap-cover {
  display: flex;
}
.poster-wrap-cover-box {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-flow: column;
  flex: 1;
  justify-content: center;
}

#drawer-form {
  border-top: 1px solid rgba(0,0,0,0.06);
  margin-top: 20px;
}
#drawer-form .ant-form-item {
  width: 100%;
  margin-right: 0;
  margin-bottom: 0px;
}

.row1 {
  padding: 20px 0;
  border-bottom: 1px solid rgba(0,0,0,0.06);
}

.cascader-box-con {
  height: auto;
  overflow: hidden;
}
.cascader-box {
  width: 194px;
  margin-right: 10px;
}
.cascader-box-right i {
  color: #1890FF;
  margin-left: 5px;
  cursor: pointer;
}

.log-btns {
  line-height: 30px;
}
.log-table {
  padding-top: 20px;
}
.log-page {
  padding: 10px 0;
}

.tips-content {
  margin: -24px 0;
}
.tips-row {
  border-bottom: 1px solid rgba(0,0,0,0.06);
  padding: 60px 0 40px;
}
.tips-row.nobor {
  border-bottom: none;
}
.tips-row dl {
  float: left;
  min-width: 200px;
}
.tips-row dl dt {
  float: left;
}
.tips-row dl dd {
  float: left;
}
.tips-row .ant-switch {
  margin-right: 5px;
}

.newAccount {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(255,255,255,0.90);
  text-align: center;
}
.newAccount-box {
  position: absolute;
  width: 940px;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
}
.new-account-btn {
  margin-top: 50px;
}
.auth-box-ul {
  display: block;
  width:100%;
  height:auto;
  overflow:hidden;
}
.auth-box-ul li {
  width: 33%;
  float: left;
  margin-top: 10px;
}

.paslabel {
  display: block;
  height: auto;
  overflow: hidden;
  margin: 4px 0 0 5px;
}
.paslabel span {
  display: inline-block;
  font-size: 12px;
  height: 22px;
  line-height: 22px;
  border-radius: 2px;
  float: left;
  background: rgba(0,0,0,0.04);
  color: rgba(0,0,0,0.65);
  margin-right: 5px;
  width: 40px;
  text-align: center;
  border: 1px solid rgba(0,0,0,0.15);
}
.paslabel span.bgred {
  background: #fff1f0!important;
  border-color: #ffa39e;
  color: #f5222d;
}
.paslabel span.bgyellow {
  background: #fff7e6!important;
  border-color: #ffd591;
  color: #fa8c16;
}
.paslabel span.bggreen {
  background: #f6ffed!important;
  /* border-color: #b7eb8; */
  color: #52c41a;
}

.loginTipsBtnBox {
  padding-bottom: 10px;
}

.schoolidlink {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
.schoolidlink:hover {
  text-decoration: underline;
}
.batch_search {
  font-size: 14px;
  font-weight: normal;
  text-decoration: underline;
}

.detail-log-table {
  width: 100%;
}
.tablehead {
  width: 100%;
  display: flex;
}
.tableheadcolumn {
  flex: 1;
  /*width: 100%;*/
  /*float: left;*/
  height: 30px;
  background: #fafafa;
  line-height: 30px;
  border-bottom: 1px solid #e8e8e8;
  font-weight: bold;
  padding: 0 20px;
}
.tableheadcolumn1 {
  /*width: 20%;*/
  flex: 0 0 20%;
}
.tableheadcolumn2 {
  /*width: 40%;*/
}
.tableheadcolumn3 {
  /*width: 40%;*/
}
.tablebody {
  width: 100%;
}
.tablebodyh {
  display: flex;
  width: 100%;
}
.tablebodycolumn {
  float: left;
  line-height: 30px;
  border-bottom: 1px solid #e8e8e8;
  flex: 1;
  padding: 0 20px;
}
.tablebodycolumn1 {
  /*width: 20%;*/
  flex: 0 0 20%;
}
.tablebodycolumn2 {
  /*width: 40%;*/
}
.tablebodycolumn3 {
  /*width: 40%;*/
}

.content-table {
  position: relative;
}
.itemshowbox {
  position: absolute;
  right: 12px;
  top: 4px;
  z-index: 9;
}

.columnfilterbox {
  width: 145px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px -4px rgba(0,0,0,0.12);
  box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.08);
  box-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05);
}
.columnfilterboxtitle {
  padding: 0 10px;
  border-bottom: 1px solid #eee;
  line-height: 30px;
}
.columnfilterboxtitle a {
  float: right;
}
.columnfilterboxtitle p {
  float: left;
  font-size: 14px;
  color: rgba(0,0,0,0.85);
  font-weight: 500;
  margin: 0;
  padding: 0;
}
.columnfilterboxcon {
  padding: 5px 10px;
}
</style>
